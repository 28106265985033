import { AppDispatch, IRootState } from '../../redux';
import { cancelSubscription } from '../../redux/thunks/purchaseThunks';

import React, { Fragment, useId } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ILicense, IPurchase } from 'marketplace-common/dist';

import styles from './MyPurchases.module.scss';
import PurchaseItem from './PurchaseItem/PurchaseItem';

const MyPurchases = () => {
    const { purchases, licenses } = useSelector((state: IRootState) => state.purchase);
    const dispatch = useDispatch<AppDispatch>();

    let purchaseList: JSX.Element | JSX.Element[] = <p>no purchases</p>;
    if (purchases?.length) {
        purchaseList = purchases.map((purchase: IPurchase, index) => {
            return (
                <Fragment key={purchase.uid + index}>
                    <PurchaseItem purchase={purchase} />
                </Fragment>
            );
        });
    }
    const handleCancel = (license: ILicense) => {
        dispatch(cancelSubscription({ uid: license.uid }));
    };
    let licenseList: JSX.Element | JSX.Element[] = <p>no licenses</p>;
    if (licenses?.length) {
        licenseList = licenses.map((license: ILicense, index) => {
            return (
                <Fragment key={license.name + index}>
                    <p>{license.name}</p>
                    <button onClick={() => handleCancel(license)}>cancel</button>
                </Fragment>
            );
        });
    }

    return (
        <div className={styles.myItems}>
            <div>{purchaseList}</div>
            <div>{licenseList}</div>
        </div>
    );
};

export default MyPurchases;
