import { AppDispatch, IRootState } from '../../redux';
import { myItemsActions } from '../../redux/slices/myItemsSlice';
import {
    loadMyBundles,
    loadMyRedwoodPublications,
    loadScenes,
    toggleAvailable,
} from '../../redux/thunks/myItemsThunks';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Outlet, useLocation } from 'react-router-dom';

import { PUBLIC_USER } from 'marketplace-common/dist';

import styles from './MyItems.module.scss';
import './MyItems.scss';

const MyItems = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const isBundles = location.pathname === `/${t('routes.my-items')}/${t('routes.bundles')}`;
    const { user, myItems } = useSelector((state: IRootState) => state);
    const { showListed, createBundle } = myItems;

    useEffect(() => {
        if (user.user.id !== -1) {
            dispatch(loadScenes({ listed: showListed }));
            dispatch(loadMyRedwoodPublications({ listed: showListed }));
            dispatch(loadMyBundles({ listed: showListed }));
        }
    }, [user]);

    const handleToggleAvailable = () => {
        dispatch(toggleAvailable(showListed));
    };
    const handleInitiateBundle = () => {
        dispatch(myItemsActions.setCreateBundle(true));
    };

    return (
        <div className={`${styles.myItems} myItems`}>
            <div className={styles.navbar}>
                <nav>
                    <NavLink to={'/' + t('routes.my-items') + '/' + t('routes.books')}>
                        {t('titles.books')}
                    </NavLink>
                    <NavLink to={'/' + t('routes.my-items') + '/' + t('routes.files')}>
                        {t('titles.files')}
                    </NavLink>
                    <NavLink to={'/' + t('routes.my-items') + '/' + t('routes.materials')}>
                        {t('titles.materials')}
                    </NavLink>
                    {/*todo add when added*/}
                    {/*<NavLink to={'/' + t('routes.my-items') + '/' + t('routes.exercises')}>*/}
                    {/*    {t('titles.exercises')}*/}
                    {/*</NavLink>*/}
                    <NavLink to={'/' + t('routes.my-items') + '/' + t('routes.bundles')}>
                        {t('titles.bundles')}
                    </NavLink>
                </nav>
                {!createBundle && (
                    <button onClick={handleInitiateBundle}>{t('create.initiate-bundle')}</button>
                )}
                {!isBundles && (
                    <button onClick={handleToggleAvailable}>
                        {showListed ? 'show unlisted' : 'show listed'}
                    </button>
                )}
            </div>

            <Outlet />
        </div>
    );
};

export default MyItems;
