import { AppDispatch, IRootState } from '../../../redux';
import { uiActions } from '../../../redux/slices/uiSlice';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Cart.module.scss';

const Cart = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { totalItems } = useSelector((state: IRootState) => state.cart);

    const openCart = () => {
        dispatch(uiActions.toggleCart());
    };
    return (
        <div onClick={openCart} className={styles.chart}>
            <span className={styles.numberContainer}>
                <span>{totalItems}</span>
            </span>
            <i className="icon-trolley-1" />
        </div>
    );
};

export default Cart;
