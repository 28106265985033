import { IStoreContentState } from '../interfaces/IStoreContentState';
import { loadMaterials, loadRedwoodPublications } from '../thunks/storeContentThunks';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IStoreContentState = {
    materials: [],
    files: [],
    exercises: [],
    publications: [],
};

const storeContentSlice = createSlice({
    name: 'storeContent',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loadMaterials.fulfilled, (state: IStoreContentState, action) => {
            state.materials = action.payload.data;
        });
        builder.addCase(loadRedwoodPublications.fulfilled, (state: IStoreContentState, action) => {
            state.publications = action.payload.data;
        });
    },
});

export const storeContentActions = storeContentSlice.actions;

export default storeContentSlice.reducer;
