import Currency from '../../../Elements/Currency/Currency';
import { AppDispatch, IRootState } from '../../../redux';
import { addShippingAddress, purchaseCart } from '../../../redux/thunks/cartThunks';

import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { IAddress } from 'marketplace-common/dist';

import AddressItem from './AddressItem/AddresItem';
import styles from './AddressSelect.module.scss';

const AddressSelect = (props: { createNew: () => void }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { addresses, shipping } = useSelector((state: IRootState) => state.cart);
    const handleSelect = (address: IAddress) => {
        dispatch(addShippingAddress(address));
    };

    const addressList = [
        <Fragment key={0}>
            <AddressItem createNew={props.createNew} />
        </Fragment>,
        ...addresses.map((address: IAddress, index: number) => (
            <Fragment key={index + 1}>
                <AddressItem
                    selected={shipping?.uid}
                    selectAddress={() => handleSelect(address)}
                    item={address}
                />
            </Fragment>
        )),
    ];

    return (
        <div className={styles.addressSelect}>
            <h3>select address</h3>
            <div className={styles.addressList}>{addressList}</div>
        </div>
    );
};

export default AddressSelect;
