import { OAUTH } from '../../OAUTH';
import * as params from '../../configuration/params.json';
import { IRootState } from '../../redux';

import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    COMMANDS,
    IProduct,
    IShoppingCartItem,
    PURCHASE_TYPE,
    QUERIES,
} from 'marketplace-common/dist';
import { v4 as uuid } from 'uuid';

import placeholder from '../../assets/images/placeholder-scene.jpg';

import MaterialItem from './MaterialItem/MaterialItem';
import styles from './Materials.module.scss';

const Materials = () => {
    const { materials } = useSelector((state: IRootState) => state.storeContent);

    useEffect(() => {}, []);
    return (
        <div className={styles.materials}>
            {!materials?.length && <p>Loading...</p>}
            {materials?.length &&
                materials.map((product: IProduct, index) => (
                    <Fragment key={product.productRef.productName + index}>
                        <MaterialItem product={product} />
                    </Fragment>
                ))}
        </div>
    );
};

export default Materials;
