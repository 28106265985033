import { LOCAL_STORAGES } from '../constants/LOCAL_STORAGES';
import { IMyItemsState } from '../interfaces/IMyItemsState';
import {
    listBundle,
    listPublication,
    listScene,
    loadMyRedwoodPublications,
    loadScenes,
    toggleAvailable,
} from '../thunks/myItemsThunks';
import { createSlice } from '@reduxjs/toolkit';

import { IScene } from 'maple-common/dist';
import { IProduct } from 'marketplace-common/dist';
import { IPublication } from 'redwood-model/dist';

const showListed = JSON.parse(localStorage.getItem(LOCAL_STORAGES.SHOW_LISTED) || 'true');

const initialState: IMyItemsState = {
    source: {
        scenes: [],
        files: [],
        exercises: [],
        publications: [],
    },
    store: {
        scenes: [],
        files: [],
        exercises: [],
        publications: [],
        bundles: [],
    },
    selected: undefined,
    showListed: showListed,
    createBundle: false,
};

const myItemsSlice = createSlice({
    name: 'myItems',
    initialState,
    reducers: {
        setSelected(state: IMyItemsState, action) {
            state.selected = action.payload;
        },
        setCreateBundle(state: IMyItemsState, action) {
            state.createBundle = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadScenes.fulfilled, (state: IMyItemsState, action) => {
            const { source, store } = action.payload;
            if (source?.length > 0) {
                source.forEach((scene: IScene) => {
                    if (!state.source.scenes.find((sc) => sc.id === scene.id)) {
                        state.source.scenes.push(scene);
                    }
                });
            }
            if (store?.length > 0) {
                store.forEach((scene: IProduct) => {
                    if (!state.store.scenes.find((sc) => sc.uid === scene.uid)) {
                        state.store.scenes.push(scene);
                    }
                });
            }
        });
        builder.addCase(loadMyRedwoodPublications.fulfilled, (state: IMyItemsState, action) => {
            const { source, store } = action.payload;
            const filteredSource = source.filter(
                (pub: IPublication) =>
                    !store.find((product: IProduct) => product.productRef.publication_id === pub.id)
            );
            if (filteredSource?.length > 0) {
                filteredSource.forEach((pub: IPublication) => {
                    if (!state.source.publications.find((sc) => sc.id === pub.id)) {
                        state.source.publications.push(pub);
                    }
                });
            }
            if (store?.length > 0) {
                store.forEach((pub: IProduct) => {
                    if (!state.store.publications.find((sc) => sc.uid === pub.uid)) {
                        state.store.publications.push(pub);
                    }
                });
            }
        });
        builder.addCase(listBundle.fulfilled, (state: IMyItemsState, action) => {
            if (action.payload) {
                state.selected = undefined;
            }
        });
        builder.addCase(listScene.fulfilled, (state: IMyItemsState, action) => {
            if (action.payload && action.payload.id) {
                state.source.scenes = state.source.scenes.filter(
                    (scene) => scene.id !== action.payload.id
                );
                state.selected = undefined;
            }
        });
        builder.addCase(listPublication.fulfilled, (state: IMyItemsState, action) => {
            if (action.payload && action.payload.id) {
                state.source.scenes = state.source.scenes.filter(
                    (scene) => scene.id !== action.payload.id
                );
                state.selected = undefined;
            }
        });
        builder.addCase(toggleAvailable.fulfilled, (state: IMyItemsState, action) => {
            state.showListed = !state.showListed;
            state.selected = undefined;
            localStorage.setItem(LOCAL_STORAGES.SHOW_LISTED, JSON.stringify(state.showListed));
        });
        builder.addCase(toggleAvailable.pending, (state: IMyItemsState, action) => {
            // state.source.scenes = [];
            // state.store.scenes = [];
            // state.source.publications = [];
            // state.store.publications = [];
            localStorage.setItem(LOCAL_STORAGES.SHOW_LISTED, JSON.stringify(state.showListed));
        });
    },
});

export const myItemsActions = myItemsSlice.actions;

export default myItemsSlice.reducer;
