import { filterActions } from '../slices/filterSlice';
import { sortActions } from '../slices/sortSlice';
import { loadMaterials, loadRedwoodPublications } from '../thunks/storeContentThunks';
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';

const filtersListenerMiddleware = createListenerMiddleware();

filtersListenerMiddleware.startListening({
    matcher: isAnyOf(
        filterActions.changeFilter,
        filterActions.changeSearchTerm,
        sortActions.selectSortParameter
    ),
    // actionCreator: filterActions.changeFilter,
    effect: async (action, listenerApi) => {
        listenerApi.dispatch(loadMaterials());
        listenerApi.dispatch(loadRedwoodPublications());
    },
});
export default filtersListenerMiddleware;
