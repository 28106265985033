import Cartbar from '../Cartbar/Cartbar';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import Titlebar from '../Titlebar/Titlebar';
import Userbar from '../Userbar/Userbar';
import { OAUTH } from '../../OAUTH';
import { AppDispatch, IRootState } from '../../redux';
import { FILTER_GROUPS } from '../../redux/constants/FILTER_GROUPS';
import { filterActions } from '../../redux/slices/filterSlice';
import { uiActions } from '../../redux/slices/uiSlice';
import { getAddresses, queryCart } from '../../redux/thunks/cartThunks';
import { getCourses } from '../../redux/thunks/dataThunks';
import { getLicenses, getPurchases } from '../../redux/thunks/purchaseThunks';
import { loadMaterials, loadRedwoodPublications } from '../../redux/thunks/storeContentThunks';
import { getCurrentUser } from '../../redux/thunks/userThunks';
import { useEffectOnce } from '../../utilities/customHooks/useEffectOnce';
import Books from '../../Pages/Books/Books';
import Bundles from '../../Pages/Bundles/Bundles';
import Exercises from '../../Pages/Exercises/Exercises';
import Files from '../../Pages/Files/Files';
import Materials from '../../Pages/Materials/Materials';
import MyItems from '../../Pages/MyItems/MyItems';
import MyBundles from '../../Pages/MyItems/subPages/Bundles/MyBundles';
import MyExercises from '../../Pages/MyItems/subPages/Exercises/MyExercises';
import MyFiles from '../../Pages/MyItems/subPages/Files/MyFiles';
import MyMaterials, { MemoizedMyMaterials } from '../../Pages/MyItems/subPages/Materials/MyMaterials';
import MyPublications from '../../Pages/MyItems/subPages/Publications/MyPublications';
import MyPurchases from '../../Pages/MyPurchases/MyPurchases';

import React, { EventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { PRODUCT_TYPE } from 'marketplace-common/dist';

import styles from './Store.module.scss';

const Store = () => {
    OAUTH.onReady = () => {};

    const { t, i18n } = useTranslation();
    const {
        side: sideVisible,
        cart: cartVisible,
        user: userVisible,
    } = useSelector((state: IRootState) => state.ui);
    const { user } = useSelector((state: IRootState) => state.user);
    const showBackdorp = cartVisible || userVisible;
    const { isMerchant } = useSelector((state: IRootState) => state.user);
    const routesWithCollectionControls = [
        t('routes.books'),
        t('routes.files'),
        t('routes.materials'),
        t('routes.exercises'),
        t('routes.bundles'),
        t('routes.my-items') + '/' + t('routes.materials'),
        t('routes.my-items') + '/' + t('routes.books'),
        t('routes.my-items') + '/' + t('routes.files'),
        t('routes.my-items') + '/' + t('routes.exercises'),
        t('routes.my-items') + '/' + t('routes.bundles'),
        t('routes.my-items'),
        t('routes.my-purchases'),
    ];
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const locationProductTypeTable = {
        [t('routes.books')]: [PRODUCT_TYPE.REDWOOD, PRODUCT_TYPE.HEMLOCK],
        [t('routes.files')]: [PRODUCT_TYPE.PRUNUS],
        [t('routes.materials')]: [PRODUCT_TYPE.MAPLE],
        // [t('routes.exercises')]: [PRODUCT_TYPE.MAPLE],
    };

    useEffect(() => {
        const path = location.pathname.replace('/', '');
        const lastPath = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
        if (locationProductTypeTable[lastPath]) {
            dispatch(filterActions.changeGroups(locationProductTypeTable[lastPath]));
        }
        if (routesWithCollectionControls.indexOf(path) !== -1) {
            dispatch(uiActions.showCollectionControls());
        } else {
            dispatch(uiActions.hideCollectionControls());
        }
    }, [location]);
    let visible = window.innerWidth < 1070;

    const handleWindowEvent = () => {
        if (visible && window.innerWidth < 1070 ) {
            visible = false;
            dispatch(uiActions.hideCollectionControls());
        }
        if (!visible && window.innerWidth > 1070) {
            visible = true;
            dispatch(uiActions.showCollectionControls());
        }

    };

    // Add the event listener within the useEffect hook
    useEffect(() => {
        // Attach the event listener to the window object
        window.addEventListener('resize', handleWindowEvent);
        // setFilterVisible(window.innerWidth < 1070)

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleWindowEvent);
        };
    }, []);

    const handleBackdrop = () => {
        dispatch(uiActions.backDropClicked());
    };

    return (
        <div>
            {showBackdorp && <div onClick={handleBackdrop} className={styles.backDrop} />}
            <div className={styles.barsContainer}>
                <Titlebar />
                <div className={`${styles.navbarContainer} ${!sideVisible ? styles.moved : ''}`}>
                    <Navbar />
                </div>
            </div>
            {}
            <div className={`${styles.sidebarContainer} ${!sideVisible ? styles.hidden : ''}`}>
                <Sidebar />
            </div>
            <div className={`${styles.main} ${!sideVisible ? styles.moved : ''}`}>
                <section className={styles.content}>
                    <Routes>
                        <Route index element={<Navigate replace to={'/' + t('routes.books')} />} />
                        <Route path={t('routes.books')} element={<Books />} />
                        <Route path={t('routes.files')} element={<Files />} />
                        <Route path={t('routes.materials')} element={<Materials />} />
                        <Route path={t('routes.exercises')} element={<Exercises />} />
                        <Route path={t('routes.bundles')} element={<Bundles />} />
                        <Route
                            path={t('routes.my-items')}
                            element={
                                isMerchant ? (
                                    <MyItems />
                                ) : (
                                    <Navigate replace to={'/' + t('routes.403')} />
                                    // <MyItems />
                                )
                            }
                        >
                            <Route
                                index
                                element={<Navigate replace to={t('routes.materials')} />}
                            />
                            <Route path={t('routes.books')} element={<MyPublications />} />
                            <Route path={t('routes.files')} element={<MyFiles />} />
                            <Route path={t('routes.materials')} element={<MyMaterials />} />
                            <Route path={t('routes.exercises')} element={<MyExercises />} />
                            <Route path={t('routes.bundles')} element={<MyBundles />} />
                        </Route>
                        <Route path={t('routes.my-purchases')} element={<MyPurchases />} />
                    </Routes>
                </section>
            </div>
            <div className={`${styles.cartContainer} ${!cartVisible ? styles.hidden : ''}`}>
                <Cartbar />
            </div>
            {user.id !== -1 && (
                <div className={`${styles.userContainer} ${!userVisible ? styles.hidden : ''}`}>
                    <Userbar />
                </div>
            )}
        </div>
    );
};

export default Store;
