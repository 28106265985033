import { getValuta } from '../../utilities/utilities';

import React from 'react';

import { t } from 'i18next';

import styles from './ShopButton.module.scss';

const ShopButton = (props: {
    clickHandler: () => void;
    price: number;
    type: 'paper' | 'digital' | 'lease';
}) => {
    const { price, type, clickHandler } = props;
    let iconClass;
    let name;

    switch (type) {
        case 'digital':
            iconClass = 'icon-laptop-1';
            name = t('buttonTypes.digital');
            break;
        case 'lease':
            iconClass = 'icon-timer-1';
            name = t('buttonTypes.lease');
            break;
        default:
            iconClass = 'icon-book-6';
            name = t('buttonTypes.paper');
    }
    const handleClick  = (e: React.MouseEvent<HTMLLabelElement>) => {
        e.stopPropagation();
        clickHandler();
    }

    return (
        <div className={styles.buttonGroup}>
            <label onClick={(e) => handleClick(e)} >{name}</label>
            <button onClick={(e) => handleClick(e)}>
                <i className={iconClass} /> {getValuta(price)}
            </button>
        </div>
    );
};

export default ShopButton;
