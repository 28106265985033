import Currency from '../../../Elements/Currency/Currency';
import ShippingForm from '../../../Elements/ShippingForm/ShippingForm';
import { AppDispatch, IRootState } from '../../../redux';
import { ISetShipping } from '../../../redux/interfaces/ISetShipping';
import { addShippingAddress, purchaseCart } from '../../../redux/thunks/cartThunks';
import AddressSelect from '../AddressSelect/AddressSelect';

import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styles from '../Cartbar.module.scss';

const Step2 = (props: { changeStep: (num: number) => void; cartTotal: number }) => {
    const { t, i18n } = useTranslation();
    const [showForm, setShowForm] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { items, totalPurchase, totalLease, totalPhysical, pending, shipping } = useSelector(
        (state: IRootState) => state.cart
    );
    const { first_name: firstName, last_name: lastName } = useSelector(
        (state: IRootState) => state.user.user
    );
    const shippingValue = shipping?.value;
    const names = { firstName, lastName };
    const handlePurchase = () => {
        dispatch(purchaseCart({ licenses: false }));
    };
    const handleCreateNew = () => {
        setShowForm(true);
    };
    const handleCancel = () => {
        setShowForm(false);
    };
    const handlePost = (data: ISetShipping) => {
        dispatch(addShippingAddress(data));
        setShowForm(false);
    };

    return (
        <div>
            <AddressSelect createNew={handleCreateNew} />
            {showForm && <ShippingForm names={names} cancel={handleCancel} post={handlePost} />}
            <div className={`${styles.summaries} ${styles.wide}`}>
                {shippingValue && (
                    <div className={styles.subTotal}>
                        <div>
                            <span>{t('cart.total-shipping')}</span>{' '}
                            <span>
                                <Currency>{shippingValue}</Currency>
                            </span>
                        </div>
                    </div>
                )}
                <div className={styles.subTotal}>
                    <div>
                        <span>{t('cart.total-complete')}</span>{' '}
                        <span>
                            <Currency>{props.cartTotal + (shippingValue || 0)}</Currency>
                        </span>
                    </div>
                </div>
            </div>
            <div className={styles.cartActions}>
                <button disabled={pending} onClick={() => props.changeStep(1)}>
                    {t('cart.prev')}
                </button>
                <button disabled={pending} onClick={handlePurchase}>
                    {t('cart.submit')}
                </button>
            </div>
        </div>
    );
};

export default Step2;
