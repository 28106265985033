import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { OAUTH } from './OAUTH';
import './i18n';

const container = document.getElementById('root');
const root = createRoot(container!);

OAUTH.onReady = () => {
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
};

OAUTH.start();
