import { FILTER_GROUPS } from '../constants/FILTER_GROUPS';
import { LOCAL_STORAGES } from '../constants/LOCAL_STORAGES';
import { IFilterState } from '../interfaces/filters/IFilterState';
import { getFilters } from '../thunks/filtersThunks';
import { createSlice } from '@reduxjs/toolkit';

import { PRODUCT_TYPE } from 'marketplace-common/dist';

const initialState: IFilterState = {
    groups: [
        { name: FILTER_GROUPS.COURSE },
        { name: FILTER_GROUPS.YEAR },
        { name: FILTER_GROUPS.SUPPLIER },
        { name: FILTER_GROUPS.EDITION },
    ],
    filters: [],
    searchTerm: localStorage.getItem(LOCAL_STORAGES.SEARCH) || '',
};
const productTypeGroupsTable = {
    BOOK: [
        { name: FILTER_GROUPS.COURSE },
        { name: FILTER_GROUPS.YEAR },
        { name: FILTER_GROUPS.SUPPLIER },
        { name: FILTER_GROUPS.EDITION },
    ],
    [PRODUCT_TYPE.PRUNUS]: [{ name: FILTER_GROUPS.SUPPLIER }],
    [PRODUCT_TYPE.MAPLE]: [
        { name: FILTER_GROUPS.COURSE },
        { name: FILTER_GROUPS.YEAR },
        { name: FILTER_GROUPS.SUPPLIER },
    ],
};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        changeFilter(state: IFilterState, action) {
            const foundOption = state.filters.find((filter) => filter.uid === action.payload);
            if (foundOption) {
                foundOption.value = !foundOption.value;
            }
            localStorage.setItem(LOCAL_STORAGES.FILTERS, JSON.stringify(state.filters));
        },
        changeGroups(state: IFilterState, action) {
            let productType = action.payload;
            if (productType.includes(PRODUCT_TYPE.REDWOOD || PRODUCT_TYPE.HEMLOCK)) {
                productType = 'BOOK';
            }
            const groups = productTypeGroupsTable[productType];
            state.groups = groups;
        },
        changeSearchTerm(state: IFilterState, action) {
            if (action.payload) {
                state.searchTerm = action.payload;
                localStorage.setItem(LOCAL_STORAGES.SEARCH, state.searchTerm);
            } else {
                state.searchTerm = '';
                localStorage.removeItem(LOCAL_STORAGES.SEARCH);
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getFilters.fulfilled, (state: IFilterState, action) => {
            state.filters = action.payload.data.filters;
            localStorage.setItem(LOCAL_STORAGES.FILTERS, JSON.stringify(state.filters));
        });
    },
});
export const filterActions = filterSlice.actions;

export default filterSlice.reducer;
