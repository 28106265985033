import React, { useEffect, useState } from 'react';

import styles from './Rating.module.scss';

const Rating = (props: {
    value: number;
    setRating?: (rating: number) => void;
    readonly?: boolean;
    max?: number;
}) => {
    const readonly = !!props.readonly;
    const max = props.max || 5;
    const values = Array.from({ length: max }, (_, i) => i + 1);
    const [value, setValue] = useState(0);
    const starClicked = (val: number) => {
        if (props.setRating) {
            const newValue = val - 1;
            setValue(newValue);
            props.setRating(max - newValue);
        }
    };
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    const stars = values
        .map((val, index) => {
            return (
                <i
                    key={index + 'star'}
                    onClick={() => starClicked(val)}
                    data-value={val}
                    className={`${styles.star} ${index + 1 <= value ? styles.filled : ''}`}
                />
            );
        })
        .reverse();

    return (
        <div className={styles.rating}>
            <div className={`${styles.starContainer} ${readonly ? 'readonly' : ''}`}>{stars}</div>
        </div>
    );
};

export default Rating;
