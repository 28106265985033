import { IRootState } from '../../redux';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import styles from './Navbar.module.scss';
import './Navbar.scss';

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const { isMerchant, user } = useSelector((state: IRootState) => state.user);
    return (
        <div className={`navbar ${styles.navbar}`}>
            <NavLink to={t('routes.books')}>{t('titles.books')}</NavLink>
            <NavLink to={t('routes.files')}>{t('titles.files')}</NavLink>
            <NavLink to={t('routes.materials')}>{t('titles.materials')}</NavLink>
            {/*todo: add when needed*/}
            {/*<NavLink to={t('routes.exercises')}>{t('titles.exercises')}</NavLink>*/}
            <NavLink to={t('routes.bundles')}>{t('titles.bundles')}</NavLink>
            {isMerchant && <NavLink to={t('routes.my-items')}>{t('titles.my-items')}</NavLink>}
            {user.id !== -1 && (
                <NavLink to={t('routes.my-purchases')}>{t('titles.my-purchases')}</NavLink>
            )}
        </div>
    );
};

export default Navbar;
