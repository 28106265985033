import { OAUTH } from '../../OAUTH';
import * as params from '../../configuration/params.json';
import { FILTER_GROUPS } from '../constants/FILTER_GROUPS';
import { SORT_NAMES } from '../constants/SORT_NAMES';
import { IRootState } from '../index';
import { IFilterOption } from '../interfaces/filters/IFilterOption';
import { ISortItem } from '../interfaces/sort/ISortItem';
import { ISortState } from '../interfaces/sort/ISortState';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    IFindMarketplaceRedwoodPublicationsQuery,
    IFindMarketplaceScenesQuery,
    IProduct,
    QUERIES,
    Sort,
} from 'marketplace-common/dist';
import { v4 as uuid } from 'uuid';

const url = `${params.MARKETPLACE_SERVER}${params.MARKETPLACE_SERVER.slice(-1) === '/' ? '' : '/'}`;

interface IGeneralResponse {
    data: IProduct[];
}

const sortPropertyMap = {
    [SORT_NAMES.ABC]: 'name',
    [SORT_NAMES.RECENT]: 'created_at',
    [SORT_NAMES.RATING]: undefined,
    [SORT_NAMES.DOWNLOADS]: undefined,
};

const getFilters = (filters: IFilterOption[]) => {
    const courses = filters
        .filter((filter) => filter.group === FILTER_GROUPS.COURSE && filter.value)
        .map((filter: IFilterOption) => filter.type);
    const years = filters
        .filter((filter) => filter.group === FILTER_GROUPS.YEAR && filter.value)
        .map((filter: IFilterOption) => parseInt(filter.type));
    const suppliers = filters
        .filter((filter) => filter.group === FILTER_GROUPS.SUPPLIER && filter.value)
        .map((filter: IFilterOption) => filter.type);
    const editions = filters
        .filter((filter) => filter.group === FILTER_GROUPS.EDITION && filter.value)
        .map((filter: IFilterOption) => filter.type);
    return { courses, years, suppliers, editions };
};
const applyFilters = (
    body: IFindMarketplaceScenesQuery | IFindMarketplaceRedwoodPublicationsQuery,
    filters: IFilterOption[],
    searchTerm: string,
    sort: ISortState
) => {
    const activeSortKey = Object.keys(sort).find((key) => sort[key].value === true);
    if (activeSortKey) {
        const activeSort = sort[activeSortKey];
        const activeSortName = sortPropertyMap[activeSortKey];
        if (activeSortName) {
            body.orderBy = {
                name: activeSortName,
                order: activeSort.descending ? Sort.DESC : Sort.ASC,
            };
        }
    }

    const { courses, years, suppliers, editions } = getFilters(filters);
    if (courses?.length) {
        //todo just courses after server change
        body.courseCodes = courses;
    }
    if (years?.length) {
        body.years = years;
    }
    if (searchTerm.length > 0) {
        body.term = searchTerm;
    }
    if (suppliers?.length) {
        body.merchant_types = suppliers;
    }
    if (editions?.length) {
        body.book_editions = editions;
    }
};

export const loadMaterials = createAsyncThunk<IGeneralResponse, void, { state: IRootState }>(
    'storeContent/loadMaterials',
    async (_, { getState }) => {
        const { filter, sort } = getState();
        const body: IFindMarketplaceScenesQuery = {
            uid: uuid(),
            queryType: QUERIES.FindMarketplaceScenesQuery,
            from: 0,
            size: 10,
        };
        applyFilters(body, filter.filters, filter.searchTerm, sort);
        // const response = await OAUTH.authRequest(url, 'POST', { body });
        // return { data: response.result };
        return {
            data: [
                {
                    uid: uuid(),
                    productRef: {
                        productName: 'materials1',
                    },
                    price: {
                        salePrice: 56,
                        leasePrice: 10,
                    },
                    merchant: {
                        name: 'merchantName',
                        firstName: 'merchant',
                        lastName: 'Name',
                        type: 'INHOUSE',
                    },
                    year: '1',
                    courseCode: 'AAR',
                    description: 'this a description',
                    tags: 'tag1, tag2, tag3',
                },
                {
                    uid: uuid(),
                    productRef: {
                        productName: 'materials2',
                    },
                    price: {
                        salePrice: 56,
                        leasePrice: 10,
                    },
                    merchant: {
                        name: 'merchantName',
                        firstName: 'merchant',
                        lastName: 'Name',
                        type: 'INHOUSE',
                    },
                    year: '2',
                    courseCode: 'WMA',
                    description: 'this a description',
                    tags: 'tag1, tag2, tag3',
                },
                {
                    uid: uuid(),
                    productRef: {
                        productName: 'materials3',
                    },
                    price: {
                        salePrice: 56,
                        leasePrice: 10,
                    },
                    merchant: {
                        name: 'merchantName',
                        firstName: 'merchant',
                        lastName: 'Name',
                        type: 'INHOUSE',
                    },
                    year: '3',
                    courseCode: 'AAR',
                    description: 'this a description',
                    tags: 'tag1, tag2, tag3',
                },
                {
                    uid: uuid(),
                    productRef: {
                        productName: 'materials4',
                    },
                    price: {
                        salePrice: 56,
                        leasePrice: 10,
                    },
                    merchant: {
                        name: 'merchantName',
                        firstName: 'merchant',
                        lastName: 'Name',
                        type: 'INHOUSE',
                    },
                    year: '4',
                    courseCode: 'WMA',
                    description: 'this a description',
                    tags: 'tag1, tag2, tag3',
                },
                {
                    uid: uuid(),
                    productRef: {
                        productName: 'materials5',
                    },
                    price: {
                        salePrice: 56,
                        leasePrice: 10,
                    },
                    merchant: {
                        name: 'merchantName',
                        firstName: 'merchant',
                        lastName: 'Name',
                        type: 'INHOUSE',
                    },
                    year: '5',
                    courseCode: 'AAR',
                    description: 'this a description',
                    tags: 'tag1, tag2, tag3',
                },
                {
                    uid: uuid(),
                    productRef: {
                        productName: 'materials6',
                    },
                    price: {
                        salePrice: 56,
                        leasePrice: 10,
                    },
                    merchant: {
                        name: 'merchantName',
                        firstName: 'merchant',
                        lastName: 'Name',
                        type: 'INHOUSE',
                    },
                    year: '6',
                    courseCode: 'WMA',
                    description: 'this a description',
                    tags: 'tag1, tag2, tag3',
                },
                {
                    uid: uuid(),
                    productRef: {
                        productName: 'materials7',
                    },
                    price: {
                        salePrice: 56,
                        leasePrice: 10,
                    },
                    merchant: {
                        name: 'merchantName',
                        firstName: 'merchant',
                        lastName: 'Name',
                        type: 'INHOUSE',
                    },
                    year: '7',
                    courseCode: 'AAR',
                    description: 'this a description',
                    tags: 'tag1, tag2, tag3',
                },
                {
                    uid: uuid(),
                    productRef: {
                        productName: 'materials8',
                    },
                    price: {
                        salePrice: 56,
                        leasePrice: 10,
                    },
                    merchant: {
                        name: 'merchantName',
                        firstName: 'merchant',
                        lastName: 'Name',
                        type: 'INHOUSE',
                    },
                    year: '1',
                    courseCode: 'WMA',
                    description: 'this a description',
                    tags: 'tag1, tag2, tag3',
                },
            ],
        };
    }
);
export const loadRedwoodPublications = createAsyncThunk<
    IGeneralResponse,
    void,
    { state: IRootState }
>('storeContent/loadRedwoodPublications', async (_, { getState }) => {
    const { filter, sort } = getState();
    const body: IFindMarketplaceRedwoodPublicationsQuery = {
        uid: uuid(),
        queryType: QUERIES.FindMarketplaceRedwoodPublicationsQuery,
        from: 0,
        size: 10,
    };
    applyFilters(body, filter.filters, filter.searchTerm, sort);
    // const response = await OAUTH.authRequest(url, 'POST', { body });
    // return { data: response.result };
    return {
        data: [
            {
                uid: uuid(),
                productRef: {
                    productName: 'Aardrijkskunde',
                },
                year: 3,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 999,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'Aardrijkskunde',
                },
                year: 4,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'Werkmap Aardrijkskunde',
                },
                year: 1,
                courseCode: 'WMA',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'Werkmap Aardrijkskunde',
                },
                year: 2,
                courseCode: 'WMA',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'Andere Tijden',
                },
                year: 1,
                courseCode: 'GES',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'Verstedelijking',
                },
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'Kosmografie en atmosfeer',
                },
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'N@tura',
                },
                year: 2,
                courseCode: 'NAT',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'N@tura',
                },
                year: 1,
                courseCode: 'NAT',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'Werkmap Aardrijkskunde - dubbel',
                },
                year: 3,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'Werkmap Aardrijkskunde - doorstroom',
                },
                year: 3,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'Workbook Geography',
                },
                year: 1,
                courseCode: 'GEO',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'Andere Tijden',
                },
                year: 2,
                courseCode: 'GES',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'werkmap Aardrijkskunde 5/6 Draagkracht',
                },
                year: '5/6',
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'werkmap Aardrijkskunde 5/6 Opbouw en afbraak',
                },
                year: '5/6',
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'werkmap Aardrijkskunde 5ta',
                },
                year: '5ta',
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'werkmap Aardrijkskunde 5Tb"',
                },
                year: '5tb',
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'werkmap Aardrijkskunde 6T',
                },
                year: '6T',
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'Demo Materiaal',
                },
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'Werkmap Aardrijkskunde - dubbel',
                },
                year: 4,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'Werkmap Aardrijkskunde - doorstroom',
                },
                year: 4,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'book2',
                },
                year: 2,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'book3',
                },
                year: 3,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'book4',
                },
                year: 4,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'book1',
                },
                year: 1,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'book2',
                },
                year: 2,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'book3',
                },
                year: 3,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'book4',
                },
                year: 4,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'book1',
                },
                year: 1,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'book2',
                },
                year: 2,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'book3',
                },
                year: 3,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
            {
                uid: uuid(),
                productRef: {
                    productName: 'book4',
                },
                year: 4,
                courseCode: 'AAR',
                merchant: {
                    name: 'merchantName',
                    firstName: 'merchant',
                    lastName: 'Name',
                    type: 'INHOUSE',
                },
                description: 'this a description',
                tags: 'tag1, tag2, tag3',
                price: {
                    leasePrice: 5,
                    paperPrice: 10,
                    salePrice: 7,
                },
            },
        ],
    };
});
