import React from 'react';

import imgUrl from '../../assets/images/educate-me.png';

import styles from './Loader.module.scss';

const Loader = () => {
    return (
        <div className={styles.loader}>
            <div className={styles.spinnerContainer}>
                <div className={styles.spinner} />
                <img src={imgUrl} alt="educate-me" />
            </div>
        </div>
    );
};

export default Loader;
