import { IPurchaseState } from '../interfaces/IPurchaseState';
import { getLicenses, getPurchases, postRating } from '../thunks/purchaseThunks';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IPurchaseState = {
    purchases: [],
    licenses: [],
};

const purchaseSlice = createSlice({
    name: 'purchase',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPurchases.fulfilled, (state, action) => {
            const { purchases } = action.payload.data;
            if (purchases?.length) {
                state.purchases = purchases;
            }
        });
        builder.addCase(getLicenses.fulfilled, (state, action) => {
            const { licenses } = action.payload.data;
            if (licenses?.length) {
                state.licenses = licenses
                    .filter((license) => license.metadata.productRefs)
                    .map((license, index) => {
                        return {
                            uid: license.subscription,
                            name:
                                license.metadata?.productRefs?.reduce(
                                    (prev, curr) => (prev += ` ${curr.productName}`),
                                    ''
                                ) || 'unknown-product-' + index,
                        };
                    });
            }
        });
        builder.addCase(postRating.fulfilled, (state, action) => {});
    },
});
export const purchaseActions = purchaseSlice.actions;

export default purchaseSlice.reducer;
