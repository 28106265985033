import React, { ReactNode } from 'react';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';

interface ChakraModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: ReactNode;
}

const ChakraModal: React.FC<ChakraModalProps> = ({ isOpen, onClose, title, children }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent maxW="50rem">
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton style={{}} />
                <ModalBody>{children}</ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ChakraModal;
