import { IUiState } from '../interfaces/IUiState';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IUiState = {
    side: true,
    sort: true,
    search: true,
    cart: false,
    user: false,
    isLoading: true,
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        hideCollectionControls(state: IUiState) {
            state.side = false;
            state.sort = false;
            state.search = false;
        },
        showCollectionControls(state: IUiState) {
            state.side = true;
            state.sort = true;
            state.search = true;
        },
        toggleCart(state: IUiState) {
            state.cart = !state.cart;
        },
        toggleUser(state: IUiState) {
            state.user = !state.user;
        },
        toggleFilters(state: IUiState) {
            state.side = !state.side;
        },
        backDropClicked(state: IUiState) {
            state.cart = false;
            state.user = false;
        },
        setLoading(state: IUiState, action) {
            state.isLoading = action.payload;
        },
    },
});
export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
