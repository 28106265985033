import * as params from '../configuration/params.json';

import React from 'react';

import { IScene } from 'maple-common/dist';
import { IProduct } from 'marketplace-common/dist';
import { IPublication } from 'redwood-model/dist';

import placeholderPub from '../assets/images/placeholder-pub-full.png';
import placeholderScene from '../assets/images/placeholder-scene.jpg';

export const getThumbnail = (element?: IScene | IProduct) => {
    if (!element) {
        return placeholderScene;
    }

    const product: IProduct | undefined = isProduct(element);
    const scene: IScene | undefined = isScene(element);

    if (typeof scene !== 'undefined') {
        if (!scene.id || typeof scene.version === 'undefined') {
            return placeholderScene;
        }
        return `${params['PREVIEW_BUCKET']}${scene.id}-v${scene.version}.png`;
    }
    if (typeof product !== 'undefined') {
        return product.thumbUrl || placeholderPub;
    }
};

export const preventEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && event.target) {
        event.preventDefault();
    }
};

export const isProduct = (
    element: IProduct | IScene | IPublication | any
): IProduct | undefined => {
    if (!element) return;
    const keys = Object.keys(element);
    if (!keys.includes('productRef')) return;

    return element as IProduct;
};
export const isScene = (element: IProduct | IScene | IPublication | any): IScene | undefined => {
    if (!element) return;
    const keys = Object.keys(element);
    if (!keys.includes('slides')) return;

    return element as IScene;
};
export const isRedwoodPub = (
    element: IProduct | IScene | IPublication | any
): IPublication | undefined => {
    if (!element) return;
    if (!element?.properties) return;
    const keys = Object.keys(element?.properties);
    if (!keys.includes('key_prefix')) return;

    return element as IPublication;
};

export const getValuta = (input: number) => {
    const valueString = input.toLocaleString('nl-BE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
    });

    return `${valueString} €`;
};

export const makeUniqueRequest = () => {};

// export const isHemlockPub = () => {}
// export const isPrunusResource = () => {}
