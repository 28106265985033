import React, { Fragment, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import styles from './Option.module.scss';

const Option = (props: {
    name: string;
    value: boolean;
    icons?: string[];
    identifier: string;
    count: number;
    onClick: MouseEventHandler;
}) => {
    const { t, i18n } = useTranslation();
    let icons = null;

    if (props.icons && props.icons.length > 0) {
        icons = props.icons.map((icon, index) => {
            return (
                <span key={'icon' + index} className={styles.icon}>
                    <i className={icon} />
                    {props.icons && props.icons?.length > 1 && index !== props.icons?.length - 1 ? (
                        <span className={styles.plus}>+</span>
                    ) : null}
                </span>
            );
        });
    }

    return (
        <div className={styles.option} onClick={props.onClick}>
            <span className={styles.left}>
                <input readOnly type="checkbox" id={props.identifier} checked={props.value} />
                <label htmlFor={props.identifier}>{t(props.name)}</label>
                {/*<span>({props.count})</span>*/}
            </span>
            <span className={styles.icons}>{icons}</span>
        </div>
    );
};

export default Option;
