import { AppDispatch, IRootState } from '../../../../redux';
import { listScene, loadScenes } from '../../../../redux/thunks/myItemsThunks';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IScene } from 'maple-common/dist';

import styles from './MyFiles.module.scss';

const MyFiles = () => {
    return <div className={styles.scenes}>files</div>;
};

export default MyFiles;
