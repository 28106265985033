import { OAUTH } from '../OAUTH';
import * as params from '../configuration/params.json';

import React from 'react';

import {
    BOOK_EDITIONS,
    COMMANDS,
    IFindMarketplaceRedwoodPublicationsQuery,
    IOfferProductCommand,
    IProduct,
    IShoppingCartItem,
    MERCHANT_TYPE,
    PRODUCT_TYPE,
    PURCHASE_TYPE,
    QUERIES,
} from 'marketplace-common/dist';
import { IResource, QUERIES as PRUNUS_QUERIES } from 'prunus-common/dist';
import { v4 as uuid } from 'uuid';

import '../App.scss';

const HTTP_VERB = 'POST';

async function emptyShoppingCart() {
    const cmdResult = await OAUTH.authRequest(params.MARKETPLACE_SERVER, HTTP_VERB, {
        body: { uid: uuid(), commandType: COMMANDS.RemoveItemsFromShoppingCartCommand },
    });
    const msg = `server responded ${JSON.stringify(cmdResult, null, 2)}`;
    console.log(msg);
    alert(msg);
}

async function addToShoppingCart() {
    const items: IShoppingCartItem[] = [
        {
            productRef: {
                publication_id: 1,
                productTypes: [PRODUCT_TYPE.REDWOOD],
                productName: 'aardrijkskunde 1',
            },
            price: {
                salePrice: 25,
            },
            quantity: 1,
            purchaseType: PURCHASE_TYPE.SALE,
            edition: BOOK_EDITIONS.PAPER,
        },
        {
            productRef: {
                scene_uid: 'f702671a-b586-11ec-b909-0242ac120002',
                productTypes: [PRODUCT_TYPE.MAPLE],
                productName: 'een maple aardrijkskunde scene',
            },
            price: {
                leasePrice: 5,
            },
            quantity: 1,
            purchaseType: PURCHASE_TYPE.LEASE,
        },
    ];
    const cmdResult = await OAUTH.authRequest(params.MARKETPLACE_SERVER, HTTP_VERB, {
        body: { uid: uuid(), commandType: COMMANDS.AddItemsToShoppingCartCommand, items },
    });
    const msg = `server responded ${JSON.stringify(cmdResult, null, 2)}`;
    console.log(msg);
    alert(msg);
}

async function queryShoppingCart() {
    const queryResult = await OAUTH.authRequest(params.MARKETPLACE_SERVER, HTTP_VERB, {
        body: { uid: uuid(), queryType: QUERIES.GetShoppingCartQuery },
    });
    const msg = `server responded ${JSON.stringify(queryResult, null, 2)}`;
    console.log(msg);
    alert(msg);
}

async function editShoppingCart() {
    const items: IShoppingCartItem[] = [
        {
            productRef: {
                publication_id: 1,
                productTypes: [PRODUCT_TYPE.REDWOOD],
                productName: 'productName',
            },
            price: {
                salePrice: 5,
            },
            quantity: 9,
            purchaseType: PURCHASE_TYPE.SALE,
        },
    ];
    const cmdResult = await OAUTH.authRequest(params.MARKETPLACE_SERVER, HTTP_VERB, {
        body: { uid: uuid(), commandType: COMMANDS.EditItemsInShoppingCartCommand, items },
    });
    const msg = `server responded ${JSON.stringify(cmdResult, null, 2)}`;
    console.log(msg);
    alert(msg);
}

async function offerSceneInMarketplace() {
    const cmd: IOfferProductCommand = {
        uid: uuid(),
        commandType: COMMANDS.OfferProductCommand,
        productRef: {
            scene_uid: 'f702671a-b586-11ec-b909-0242ac120002',
            productTypes: [PRODUCT_TYPE.MAPLE],
            productName: 'een aardrijkskunde scene',
        },
        courseCode: 'AARD',
        licenses: [
            {
                uid: 'hbvcshjc',
                name: 'lic name',
            },
        ],
        price: {
            salePrice: 69,
            leasePrice: 10,
        } /*
        merchant: {
            type: MERCHANT_TYPE.TEACHER,
            name: 'Conrad Hague',
            uid: uuid(),
            mail: 'lkr1@e-ducate.me'
        },*/,
        description: 'sceneDescription shows up in marketplace',
        purchaseTypes: [PURCHASE_TYPE.SALE, PURCHASE_TYPE.LEASE],
    };
    const cmdResult = await OAUTH.authRequest(params.MARKETPLACE_SERVER, HTTP_VERB, {
        body: cmd,
    });
    const msg = `server responded ${JSON.stringify(cmdResult, null, 2)}`;
    console.log(msg);
    alert(msg);
}

async function offerExpensiveSceneInMarketplace() {
    const cmd: IOfferProductCommand = {
        uid: uuid(),
        commandType: COMMANDS.OfferProductCommand,
        productRef: {
            scene_uid: 'f702671a-b586-11ec-b909-0242ac120002',
            productTypes: [PRODUCT_TYPE.MAPLE],
            productName: 'kostelijke scene om geld voor payouts te verzamelen',
        },
        courseCode: 'AARD',
        licenses: [
            {
                uid: 'hbvcshjc',
                name: 'lic name',
            },
        ],
        price: {
            salePrice: 99999,
            leasePrice: 9999,
        } /*
        merchant: {
            type: MERCHANT_TYPE.TEACHER,
            name: 'Conrad Hague',
            uid: uuid(),
            mail: 'lkr1@e-ducate.me'
        },*/,
        description: 'geld voor payouts te testen',
        purchaseTypes: [PURCHASE_TYPE.SALE, PURCHASE_TYPE.LEASE],
    };
    const cmdResult = await OAUTH.authRequest(params.MARKETPLACE_SERVER, HTTP_VERB, {
        body: cmd,
    });
    const msg = `server responded ${JSON.stringify(cmdResult, null, 2)}`;
    console.log(msg);
    alert(msg);
}

async function findMarketplaceScenesQuery() {
    const queryResult = await OAUTH.authRequest(params.MARKETPLACE_SERVER, HTTP_VERB, {
        body: {
            uid: uuid(),
            queryType: QUERIES.FindMarketplaceScenesQuery,
            from: 0,
            size: 10,
        },
    });
    const msg = `server responded ${JSON.stringify(queryResult, null, 2)}`;
    console.log(msg);
    alert(msg);
}

async function offerRedwoodPubInMarketplace() {
    const cmd: IOfferProductCommand = {
        uid: uuid(),
        commandType: COMMANDS.OfferProductCommand,
        productRef: {
            publication_id: 3,
            productTypes: [PRODUCT_TYPE.REDWOOD],
            productName: 'Redwood pub aardrijkskunde',
        },
        courseCode: 'AARD',
        licenses: [
            {
                uid: '1',
                name: 'lic name',
            },
        ],
        book: {
            edition: BOOK_EDITIONS.DIGITAL,
            weight: 1600,
        },
        /*
        merchant: {
            type: MERCHANT_TYPE.INHOUSE,
            name: 'e-ducate.me',
            uid: uuid(),
            mail: 'info@e-ducate.me',
            logoUrl: 'http://e-ducate.me'
        },*/
        price: {
            salePrice: 88,
            leasePrice: 13,
        },
        description: 'redwood pub description shows up in marketplace',
        purchaseTypes: [PURCHASE_TYPE.SALE, PURCHASE_TYPE.LEASE],
        tags: 'uw moeder', // space delimitted instead of array
    };
    const cmdResult = await OAUTH.authRequest(params.MARKETPLACE_SERVER, HTTP_VERB, {
        body: cmd,
    });
    const msg = `server responded ${JSON.stringify(cmdResult, null, 2)}`;
    console.log(msg);
    alert(msg);
}

async function offerPrunusResourceInMarketplace() {
    const cmd: IOfferProductCommand = {
        uid: uuid(),
        commandType: COMMANDS.OfferProductCommand,
        productRef: {
            prunus_uri: 'f702671a-b586-11ec-b909-0242ac120002',
            productTypes: [PRODUCT_TYPE.PRUNUS],
            productName: 'image 156.jpg',
        },
        courseCode: 'AARD',
        licenses: [
            {
                uid: '1',
                name: 'lic name',
            },
        ],
        price: {
            salePrice: 45,
            leasePrice: 6,
        },
        /*
        merchant: {
            type: MERCHANT_TYPE.TEACHER,
            name: 'Conrad Hague',
            uid: uuid(),
            mail: 'lkr1@e-ducate.me'
        },*/
        description: 'redwood pub description shows up in marketplace',
        purchaseTypes: [PURCHASE_TYPE.SALE, PURCHASE_TYPE.LEASE],
        tags: 'uw moeder', // space delimitted instead of array
    };
    const cmdResult = await OAUTH.authRequest(params.MARKETPLACE_SERVER, HTTP_VERB, {
        body: cmd,
    });
    const msg = `server responded ${JSON.stringify(cmdResult, null, 2)}`;
    console.log(msg);
    alert(msg);
}

async function offerHemlockResourceInMarketplace() {
    const cmd: IOfferProductCommand = {
        uid: uuid(),
        commandType: COMMANDS.OfferProductCommand,
        productRef: {
            hemlock_uid: 'f702671a-b586-11ec-b909-0242ac120002',
            productTypes: [PRODUCT_TYPE.HEMLOCK],
            productName: 'hemlock product',
        },
        licenses: [
            {
                uid: '1',
                name: 'lic name',
            },
        ],
        courseCode: 'AARD',
        price: {
            salePrice: 45,
            leasePrice: 6,
        },
        description: 'redwood pub description shows up in marketplace',
        purchaseTypes: [PURCHASE_TYPE.SALE, PURCHASE_TYPE.LEASE],
        tags: 'uw moeder', // space delimitted instead of array
        merchant: {
            type: MERCHANT_TYPE.INHOUSE,
            name: 'e-ducate.me',
            uid: uuid(),
            mail: 'info@e-ducate.me',
            logoUrl: 'http://e-ducate.me',
        },
    };
    const cmdResult = await OAUTH.authRequest(params.MARKETPLACE_SERVER, HTTP_VERB, {
        body: cmd,
    });
    const msg = `server responded ${JSON.stringify(cmdResult, null, 2)}`;
    console.log(msg);
    alert(msg);
}

async function queryPrunusResources() {
    let url = params.PRUNUS_SERVER;
    if (!url.endsWith('/')) {
        url += '/';
    }

    const resources: IResource[] = await OAUTH.authRequest(
        params.PRUNUS_SERVER + 'universal_query/query',
        HTTP_VERB,
        {
            body: {
                query: {
                    uid: uuid(),
                    queryType: PRUNUS_QUERIES.FindResourcesByUserQuery,
                    from: 0,
                    size: 10,
                    path: '/prunus/',
                },
            },
        }
    );
    const msg = `server responded ${JSON.stringify(resources, null, 2)}`;
    console.log(msg);
    alert(msg);

    for (const resource of resources) {
        console.log(resource);
    }
}

async function buyShoppingCart() {
    const cmdResult = await OAUTH.authRequest(params.MARKETPLACE_SERVER, HTTP_VERB, {
        body: { uid: uuid(), commandType: COMMANDS.PurchaseShoppingCartCommand },
    });
    const msg = `server responded ${JSON.stringify(cmdResult, null, 2)}`;
    console.log('new location:', cmdResult.result.headers.Location);
    const w = window.open(cmdResult.result.headers.Location);
    if (w) {
        w.addEventListener('onbeforeunload', () => {});
    }
}

async function startMerchantRegistrationWizardCommand() {
    const cmdResult = await OAUTH.authRequest(params.MARKETPLACE_SERVER, HTTP_VERB, {
        body: { uid: uuid(), commandType: COMMANDS.StartMerchantRegistrationWizardCommand },
    });
    console.log(`server responded ${JSON.stringify(cmdResult, null, 2)}`);
    const w = window.open(cmdResult.result.url);
    if (w) {
        w.addEventListener('onbeforeunload', () => {});
    }
}

async function queryMyPurchases() {
    const queryResult = await OAUTH.authRequest(params.MARKETPLACE_SERVER, HTTP_VERB, {
        body: { uid: uuid(), queryType: QUERIES.FindMyPurchasesQuery },
    });
    const msg = `server responded ${JSON.stringify(queryResult, null, 2)}`;
    console.log(msg);
    alert(msg);
}

async function offerBundleCommand() {
    const cmdResult = await OAUTH.authRequest(params.MARKETPLACE_SERVER, HTTP_VERB, {
        body: {
            uid: uuid(),
            commandType: COMMANDS.OfferBundleCommand,
            bundleId: 'my fantasic bundle',
            cmds: [
                {
                    uid: uuid(),
                    commandType: COMMANDS.OfferProductCommand,
                    productRef: {
                        scene_uid: 'f702671a-b586-11ec-b909-0242ac120002',
                        productTypes: [PRODUCT_TYPE.MAPLE],
                        productName: 'een aardrijkskunde scene 1',
                    },
                    title: '',
                    courseId: 7,
                    licenses: [
                        {
                            uid: '1',
                            name: 'lic name',
                        },
                    ],
                    price: {
                        salePrice: 69,
                        leasePrice: 10,
                    },
                    description: 'sceneDescription shows up in marketplace',
                    purchaseTypes: [PURCHASE_TYPE.SALE, PURCHASE_TYPE.LEASE],
                },
                {
                    uid: uuid(),
                    commandType: COMMANDS.OfferProductCommand,
                    productRef: {
                        scene_uid: 'f702671a-b586-11ec-b909-0242ac120002',
                        productTypes: [PRODUCT_TYPE.MAPLE],
                        productName: 'een aardrijkskunde scene 2',
                    },
                    title: '',
                    courseId: 7,
                    licenses: [
                        {
                            uid: '1',
                            name: 'lic name',
                        },
                    ],
                    price: {
                        salePrice: 69,
                        leasePrice: 10,
                    },
                    description: 'sceneDescription shows up in marketplace',
                    purchaseTypes: [PURCHASE_TYPE.SALE, PURCHASE_TYPE.LEASE],
                },
            ],
        },
    });
}

async function queryMarketplaceRedwoodPublications() {
    const q: IFindMarketplaceRedwoodPublicationsQuery = {
        uid: uuid(), // to be able to correlate query results when they come back async from the server
        queryType: QUERIES.FindMarketplaceRedwoodPublicationsQuery,
        term: 'aard',
        size: 10,
        from: 0,
    };
    const queryResultBody = await OAUTH.authRequest(params.MARKETPLACE_SERVER, HTTP_VERB, {
        body: q,
    });

    const msg = `server responded ${JSON.stringify(queryResultBody, null, 2)}`;
    console.log(msg);
    alert(msg);

    const products: IProduct[] = queryResultBody.result;
    for (const product of products) {
        console.log(product);
    }
}

async function queryCourses() {
    const queryResult = await OAUTH.authRequest(params.MARKETPLACE_SERVER, HTTP_VERB, {
        body: { uid: uuid(), queryType: QUERIES.FindCoursesQuery },
    });
    const msg = `server responded ${JSON.stringify(queryResult, null, 2)}`;
    console.log(msg);
    alert(msg);
}

function Actions() {
    return (
        <div className="Test">
            <div>
                <button onClick={addToShoppingCart}>add to shopping cart</button>
            </div>
            <div>
                <button onClick={emptyShoppingCart}>empty my shopping cart</button>
            </div>
            <div>
                <button onClick={queryShoppingCart}>query my shopping cart</button>
            </div>
            <div>
                <button onClick={editShoppingCart}>edit my shopping cart</button>
            </div>
            <div>
                <button onClick={offerSceneInMarketplace}>offer scene in marketplace</button>
            </div>
            <div>
                <button onClick={findMarketplaceScenesQuery}>query scenes in market place</button>
            </div>

            <div>
                <button onClick={offerRedwoodPubInMarketplace}>
                    offer Publication in market place
                </button>
            </div>
            <div>
                <button onClick={offerPrunusResourceInMarketplace}>
                    offer prunus resource in market place
                </button>
            </div>
            <div>
                <button onClick={offerHemlockResourceInMarketplace}>
                    offer hemlock resource in market place
                </button>
            </div>
            <div>
                <button onClick={queryPrunusResources}>query Prunus for resources</button>
            </div>
            <div>
                <button onClick={buyShoppingCart}>buy shopping cart</button>
            </div>
            <div>
                <button onClick={queryMyPurchases}>query my purchases</button>
            </div>
            <div>
                <button onClick={startMerchantRegistrationWizardCommand}>
                    register as merchant wizard
                </button>
            </div>
            <div>
                <button onClick={offerBundleCommand}>offer bundle of scenes</button>
            </div>
            <div>
                <button onClick={queryMarketplaceRedwoodPublications}>
                    queryMarketplaceRedwoodPublications
                </button>
            </div>
            <div>
                <button onClick={queryCourses}>queryCourses</button>
            </div>
            <div>
                <button onClick={offerExpensiveSceneInMarketplace}>
                    offerExpensiveSceneInMarketplace
                </button>
            </div>
        </div>
    );
}

export default Actions;
