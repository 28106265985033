import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from './Elements/Loader/Loader';
import { AppDispatch, IRootState } from './redux';
import { getAddresses, queryCart } from './redux/thunks/cartThunks';
import { getFilters } from './redux/thunks/filtersThunks';
import { getLicenses, getPurchases } from './redux/thunks/purchaseThunks';
import { loadMaterials, loadRedwoodPublications } from './redux/thunks/storeContentThunks';
import { getCurrentUser } from './redux/thunks/userThunks';

const Entry = (props: { children: JSX.Element[] | JSX.Element }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { user } = useSelector((state: IRootState) => state);
    useEffect(() => {
        dispatch(getCurrentUser());
        dispatch(getFilters());
        dispatch(loadMaterials());
        dispatch(loadRedwoodPublications());
    }, []);
    useEffect(() => {
        if (user?.user?.id !== -1) {
            dispatch(queryCart());
            dispatch(getAddresses());
            dispatch(getPurchases());
            dispatch(getLicenses());
        }
    }, []);

    return !user.triedGettingUser ? <Loader /> : <>{props.children}</>;
};
export default Entry;
