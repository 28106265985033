import ShopButton from '../../../Elements/ShopButton/ShopButton';
import ShopButtonSec from '../../../Elements/ShopButton/ShopButtonSec';
import { AppDispatch } from '../../../redux';
import { addItemToCart } from '../../../redux/thunks/cartThunks';
import { getValuta } from '../../../utilities/utilities';

import React from 'react';
import { useDispatch } from 'react-redux';

import { BOOK_EDITIONS, IProduct, MERCHANT_TYPE, PURCHASE_TYPE } from 'marketplace-common/dist';

import placeholder from '../../../assets/images/placeholder-pub.png';

import styles from './BookItem.module.scss';
import { t } from 'i18next';
import ProductDetail from '../../../Elements/ProductDetail/ProductDetail';
import Modal from '../../../Elements/Modal/Modal';
import { useDisclosure } from '@chakra-ui/react';

const BookItem = (props: { product: IProduct }) => {
    const { product } = props;
    const dispatch = useDispatch<AppDispatch>();
    const addToCart = (type: string, product: IProduct) => {
        dispatch(addItemToCart({ type, item: product, quantity: 1 }));
    };
    const { isOpen, onOpen, onClose } = useDisclosure()
    let merchant;
    if (product.merchant) {
        merchant = {
            name:
                product.merchant.type === MERCHANT_TYPE.INHOUSE
                    ? product.merchant.name
                    : `${product.merchant.firstName} ${product.merchant.lastName}`,
            type: t(`suppliers.${product.merchant.type}`),
        };
    }

    const course = t(`pubPrefixes.${product.courseCode}`)
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} title={product.productRef.productName}>
                <ProductDetail
                    product={product}
                    salePrice={product.price.salePrice}
                    paperPrice={product.price.paperPrice}
                    leasePrice={product.price.leasePrice}
                    merchant={merchant}
                    course={course}
                    type='book'
                    />
            </Modal>
            <div className={styles.bookItem} onClick={onOpen}>
                <img
                    loading="lazy"
                    src={product.thumbUrl || placeholder}
                    alt={`book-cover for:${product.productRef.productName}`}
                />
                {/*<div className={styles.imgTest}></div>*/}
                <div className={styles.footer}>
                    <div className={styles.textContainer}>
                        <p>{course} {product.year}</p>
                        <h3>{product.productRef.productName}</h3>
                    </div>
                    <div className={styles.buttonContainer}>
                        {product.price.paperPrice && (
                            <ShopButtonSec
                                clickHandler={() => {
                                    addToCart(BOOK_EDITIONS.PAPER, product);
                                }}
                                price={product.price.paperPrice}
                                type="paper"
                            />
                        )}
                        {product.price.salePrice && (
                            <ShopButtonSec
                                clickHandler={() => {
                                    addToCart(PURCHASE_TYPE.SALE, product);
                                }}
                                price={product.price.salePrice}
                                type="digital"
                            />
                        )}
                        {product.price.leasePrice && (
                            <ShopButtonSec
                                clickHandler={() => {
                                    addToCart(PURCHASE_TYPE.LEASE, product);
                                }}
                                price={product.price.leasePrice}
                                type="lease"
                            />
                        )}
                    </div>
                </div>
            </div>
        </>

    );
};

export default BookItem;
