import React from 'react';
import { useTranslation } from 'react-i18next';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes, useRoutes } from 'react-router-dom';

import Actions from './Actions/Actions';
import './App.scss';
import Entry from './Entry';
import Error403 from './Pages/Errors/Error403';
import Error404 from './Pages/Errors/Error404';
import MerchantCreate from './Pages/Merchant/MerchantCreate';
import MerchantRetry from './Pages/Merchant/MerchantRetry';
import PaymentFailed from './Pages/Payment/PaymentFailed';
import PaymentSuccess from './Pages/Payment/PaymentSuccess';
import Store from './Layouts/Store/Store';
import store from './redux/index';
import { ChakraProvider } from '@chakra-ui/react'
import { ChakraBaseProvider, extendBaseTheme } from '@chakra-ui/react'
import { theme as chakraTheme } from "@chakra-ui/theme"

const { Modal, CloseButton } = chakraTheme.components
const theme = extendBaseTheme({
    components: {
        Modal,
        CloseButton
    },
})
function App() {

    // @ts-ignore
    // const { Modal, ModalCloseButton } = chakraTheme.theme.components;
    const { t, i18n } = useTranslation();
    // const theme = extendBaseTheme({
    //     components: {
    //         Modal,
    //         ModalCloseButton
    //     }
    // })

    return (
        <ChakraBaseProvider theme={theme}>
            <Provider store={store}>
                <Entry>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/*" element={<Store />} />
                            <Route path="actions" element={<Actions />} />
                            {i18n.language !== 'nl' && (
                                <Route
                                    path="success"
                                    element={<Navigate replace to={'payment/success'} />}
                                />
                            )}
                            {i18n.language !== 'nl' && (
                                <Route path="payment/success" element={<PaymentSuccess />} />
                            )}
                            {i18n.language === 'nl' && (
                                <Route
                                    path="success"
                                    element={
                                        <Navigate
                                            replace
                                            to={
                                                '/' +
                                                t('routes.payment.self') +
                                                '/' +
                                                t('routes.payment.success')
                                            }
                                        />
                                    }
                                />
                            )}
                            {i18n.language === 'nl' && (
                                <Route
                                    path="payment/success"
                                    element={
                                        <Navigate
                                            replace
                                            to={
                                                '/' +
                                                t('routes.payment.self') +
                                                '/' +
                                                t('routes.payment.success')
                                            }
                                        />
                                    }
                                />
                            )}
                            {i18n.language === 'nl' && (
                                <Route
                                    path={
                                        '/' +
                                        t('routes.payment.self') +
                                        '/' +
                                        t('routes.payment.success')
                                    }
                                    element={<PaymentSuccess />}
                                />
                            )}
                            {i18n.language !== 'nl' && (
                                <Route
                                    path="failed"
                                    element={<Navigate replace to={'payment/failed'} />}
                                />
                            )}
                            {i18n.language !== 'nl' && (
                                <Route path="payment/failed" element={<PaymentFailed />} />
                            )}
                            {i18n.language === 'nl' && (
                                <Route
                                    path="failed"
                                    element={
                                        <Navigate
                                            replace
                                            to={
                                                '/' +
                                                t('routes.payment.self') +
                                                '/' +
                                                t('routes.payment.failed')
                                            }
                                        />
                                    }
                                />
                            )}
                            {i18n.language === 'nl' && (
                                <Route
                                    path="payment/failed"
                                    element={
                                        <Navigate
                                            replace
                                            to={
                                                '/' +
                                                t('routes.payment.self') +
                                                '/' +
                                                t('routes.payment.failed')
                                            }
                                        />
                                    }
                                />
                            )}
                            {i18n.language === 'nl' && (
                                <Route
                                    path={
                                        '/' +
                                        t('routes.payment.self') +
                                        '/' +
                                        t('routes.payment.failed')
                                    }
                                    element={<PaymentFailed />}
                                />
                            )}
                            {i18n.language !== 'nl' && (
                                <Route path="merchant/create" element={<MerchantCreate />} />
                            )}
                            {i18n.language === 'nl' && (
                                <Route
                                    path="merchant/create"
                                    element={
                                        <Navigate
                                            replace
                                            to={
                                                '/' +
                                                t('routes.merchant.self') +
                                                '/' +
                                                t('routes.merchant.create')
                                            }
                                        />
                                    }
                                />
                            )}
                            {i18n.language === 'nl' && (
                                <Route
                                    path={
                                        '/' +
                                        t('routes.merchant.self') +
                                        '/' +
                                        t('routes.merchant.create')
                                    }
                                    element={<MerchantCreate />}
                                />
                            )}
                            {i18n.language !== 'nl' && (
                                <Route path="merchant/retry" element={<MerchantRetry />} />
                            )}
                            {i18n.language === 'nl' && (
                                <Route
                                    path="merchant/retry"
                                    element={
                                        <Navigate
                                            replace
                                            to={
                                                '/' +
                                                t('routes.merchant.self') +
                                                '/' +
                                                t('routes.merchant.retry')
                                            }
                                        />
                                    }
                                />
                            )}
                            {i18n.language === 'nl' && (
                                <Route
                                    path={
                                        '/' +
                                        t('routes.merchant.self') +
                                        '/' +
                                        t('routes.merchant.retry')
                                    }
                                    element={<MerchantRetry />}
                                />
                            )}
                            <Route path={t('routes.404')} element={<Error404 />} />
                            <Route path={t('routes.403')} element={<Error403 />} />
                        </Routes>
                    </BrowserRouter>
                </Entry>
            </Provider>
        </ChakraBaseProvider>
    );
}

export default App;
