import { IRootState } from '../../redux';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useMatch, useNavigationType } from 'react-router-dom';

import FilterGroup from './FilterGroup/FilterGroup';
import styles from './Sidebar.module.scss';
import Search from '../Titlebar/Search/Search';
import Sort from '../Titlebar/Sort/Sort';

const Sidebar = () => {
    const { t, i18n } = useTranslation();

    const groups = useSelector((state: IRootState) => state.filter.groups);
    const filters = useSelector((state: IRootState) => state.filter.filters);
    const filterGroups = groups.map((group) => (
        <FilterGroup
            key={group.name}
            name={t(`filters.${group.name}`)}
            options={filters.filter((filter) => filter.group === group.name)}
        />
    ));

    return <div className={styles.sidebar}>
        <Search className={styles.searchParent} type='side'/>
        <Sort className={styles.sortParent} type='side'/>
        {filterGroups}
    </div>;
};

export default Sidebar;
