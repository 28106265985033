import Currency from '../../../Elements/Currency/Currency';
import { AppDispatch, IRootState } from '../../../redux';
import { addItemToCart } from '../../../redux/thunks/cartThunks';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { IProduct, MERCHANT_TYPE, PURCHASE_TYPE } from 'marketplace-common/dist';

import placeholder from '../../../assets/images/placeholder-scene.jpg';

import styles from './MaterialItem.module.scss';
import ShopButtonSec from '../../../Elements/ShopButton/ShopButtonSec';
import { t } from 'i18next';

//todo best option for putting modal??
import { useDisclosure } from '@chakra-ui/react';
import Modal from '../../../Elements/Modal/Modal';
import ProductDetail from '../../../Elements/ProductDetail/ProductDetail';


const MaterialItem = (props: { product: IProduct }) => {
    const { t, i18n } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure()

    const dispatch = useDispatch<AppDispatch>();
    const { courses, years } = useSelector((state: IRootState) => state.data);
    const { pending } = useSelector((state: IRootState) => state.cart);
    const { product } = props;
    const salePrice = product?.price?.salePrice || -1;
    const leasePrice = product?.price?.leasePrice || -1;
    let merchant;
    if (product.merchant) {
        merchant = {
            name:
                product.merchant.type === MERCHANT_TYPE.INHOUSE
                    ? product.merchant.name
                    : `${product.merchant.firstName} ${product.merchant.lastName}`,
            type: t(`suppliers.${product.merchant.type}`),
        };
    }
    let course;
    if (product.courseCode && courses?.length) {
        course = t(`pubPrefixes.${product.courseCode}`)
    }
    let licenses;
    if (product.licenses?.length) {
        licenses = product.licenses.map((license, index) => {
            const all = product.licenses || [];
            return (
                <span key={license.name + index}>
                    {license.name}
                    {all.length > 1 && index !== all.length - 1 ? ', ' : ''}
                </span>
            );
        });
    }
    const productName = product?.productRef?.productName || 'product';

    const addToCart = (type: string) => {

        if (!pending) {
            dispatch(
                addItemToCart({
                    item: product,
                    type: type,
                    quantity: 1,
                })
            );
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} title={productName}>
                <ProductDetail
                    product={product}
                    salePrice={salePrice}
                    leasePrice={leasePrice}
                    merchant={merchant}
                    course={course}
                    type='material'
                    licenses={licenses}/>
            </Modal>
            <div className={styles.item} onClick={onOpen}>
                <div className={styles.imageContainer}>
                    <img
                        src={product.thumbUrl || placeholder}
                        alt={`${product.productRef.productName}-thumbnail`}
                        loading="lazy"
                    />
                    <div className={styles.titleContainer}>
                        {course && (
                            <p>
                                {course && <span className={styles.capitalize}>{course}</span>}
                                {product.year && <span> {product.year}</span>}
                            </p>
                        )}
                        <h3>{product.productRef.productName}</h3>
                    </div>
                    <div className={styles.extraContainer}>
                    <span>
                        {licenses && (
                            <span>
                                <span className={styles.title}>{t('item.linked')} </span>
                                {licenses}
                            </span>
                        )}
                    </span>
                        <span>
                        {merchant && (
                            <span>
                                {merchant.name} ({merchant.type})
                            </span>
                        )}
                    </span>
                    </div>

                </div>
                <div className={styles.dataContainer}>
                    <div className={styles.infoContainer}>
                        {product.tags && (
                            <p>
                                <span className={styles.title}>{t('item.tags')} </span>
                                {product.tags}
                            </p>
                        )}

                        {licenses && (
                            <p>
                                <span className={styles.title}>{t('item.linked')} </span>
                                {licenses}
                            </p>
                        )}
                    </div>
                    <div className={styles.actionsContainer}>
                        {salePrice >= 0 && (
                            <ShopButtonSec
                                clickHandler={() => addToCart(PURCHASE_TYPE.SALE)}
                                price={salePrice}
                                type="digital"
                            />
                        )}
                        {leasePrice >= 0 && (
                            <ShopButtonSec
                                clickHandler={() => addToCart(PURCHASE_TYPE.LEASE)}
                                price={leasePrice}
                                type="lease"
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MaterialItem;
