import { ICartState } from '../interfaces/ICartState';
import {
    addItemToCart,
    addShippingAddress,
    clearCart,
    getAddresses,
    queryCart,
    removeItemFromCart,
    updateItemAmount,
} from '../thunks/cartThunks';
import { createSlice } from '@reduxjs/toolkit';

import { BOOK_EDITIONS, IAddress, IShippingCost, PURCHASE_TYPE } from 'marketplace-common/dist';
import { IShoppingCartItem } from 'marketplace-common/dist/commands/IShoppingCartItem';

const initialState: ICartState = {
    items: [],
    totalPurchase: 0,
    totalLease: 0,
    totalItems: 0,
    totalPhysical: 0,
    shipping: undefined,
    pending: false,
    addresses: [],
};
const handleItems = (items: IShoppingCartItem[], state: ICartState) => {
    const totalItems = items.reduce(
        (prev: number, curr: IShoppingCartItem) => prev + curr.quantity,
        0
    );
    const totalPurchase = items
        .filter(
            (item: IShoppingCartItem) =>
                item.purchaseType === PURCHASE_TYPE.SALE &&
                item.quantity > 0 &&
                item.edition !== BOOK_EDITIONS.PAPER
        )
        .reduce((prev: number, curr: IShoppingCartItem) => {
            const typedPrice =
                curr.purchaseType === PURCHASE_TYPE.SALE
                    ? curr.price.salePrice
                    : curr.price.leasePrice;
            return prev + curr.quantity * (typedPrice || 0);
        }, 0);
    const totalPhysical = items
        .filter(
            (item: IShoppingCartItem) =>
                item.purchaseType === PURCHASE_TYPE.SALE &&
                item.quantity > 0 &&
                item.edition === BOOK_EDITIONS.PAPER
        )
        .reduce((prev: number, curr: IShoppingCartItem) => {
            const typedPrice =
                curr.edition === BOOK_EDITIONS.PAPER
                    ? curr.price.paperPrice
                    : curr.purchaseType === PURCHASE_TYPE.SALE
                    ? curr.price.salePrice
                    : curr.price.leasePrice;
            return prev + curr.quantity * (typedPrice || 0);
        }, 0);
    const totalLease = items
        .filter(
            (item: IShoppingCartItem) =>
                item.purchaseType === PURCHASE_TYPE.LEASE && item.quantity > 0
        )
        .reduce((prev: number, curr: IShoppingCartItem) => {
            const typedPrice =
                curr.purchaseType === PURCHASE_TYPE.SALE
                    ? curr.price.salePrice
                    : curr.price.leasePrice;
            return prev + curr.quantity * (typedPrice || 0);
        }, 0);
    state.items = items;
    state.totalItems = totalItems;
    state.totalLease = totalLease;
    state.totalPhysical = totalPhysical;
    state.totalPurchase = totalPurchase;
};
const handleShipping = (shippingCosts: IShippingCost[], address: IAddress, state: ICartState) => {
    const cost = shippingCosts.reduce((prev, curr) => prev + (curr.price || 0), 0);
    state.shipping = { uid: address.uid || 'no-uid', value: cost };
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(queryCart.fulfilled, (state: ICartState, action) => {
            if (action.payload) {
                const { items, shippingCosts, address } = action.payload.data;
                if (items) {
                    handleItems(items, state);
                }
                if (shippingCosts && address) {
                    handleShipping(shippingCosts, address, state);
                }
            }
            state.pending = false;
        });
        builder.addCase(addItemToCart.pending, (state: ICartState) => {
            state.pending = true;
        });
        builder.addCase(addItemToCart.fulfilled, (state: ICartState, action) => {
            if (action.payload) {
                const { items, shippingCosts, address } = action.payload.data;

                if (items) {
                    handleItems(items, state);
                }
                if (shippingCosts && address) {
                    handleShipping(shippingCosts, address, state);
                }
            }
            state.pending = false;
        });
        builder.addCase(updateItemAmount.pending, (state: ICartState, action) => {
            state.pending = true;
        });
        builder.addCase(updateItemAmount.fulfilled, (state: ICartState, action) => {
            if (action.payload) {
                const { items, shippingCosts, address } = action.payload.data;
                if (items) {
                    handleItems(items, state);
                }
                if (shippingCosts && address) {
                    handleShipping(shippingCosts, address, state);
                }
            }
            state.pending = false;
        });
        builder.addCase(removeItemFromCart.pending, (state: ICartState, action) => {
            state.pending = true;
        });
        builder.addCase(removeItemFromCart.fulfilled, (state: ICartState, action) => {
            if (action.payload) {
                const { items, shippingCosts, address } = action.payload.data;

                if (items) {
                    handleItems(items, state);
                }
                if (shippingCosts && address) {
                    handleShipping(shippingCosts, address, state);
                }
            }
            state.pending = false;
        });
        builder.addCase(clearCart.pending, (state: ICartState, action) => {
            state.pending = true;
        });
        builder.addCase(clearCart.fulfilled, (state: ICartState, action) => {
            Object.assign(state, initialState);
        });
        builder.addCase(getAddresses.fulfilled, (state: ICartState, action) => {
            if (action.payload) {
                state.addresses = action.payload.data;
            }
        });
        builder.addCase(addShippingAddress.fulfilled, (state: ICartState, action) => {
            if (action.payload) {
                const { address, shippingCosts } = action.payload.data;
                if (address) {
                    if (shippingCosts) {
                        handleShipping(shippingCosts, address, state);
                    }
                    if (
                        !state.addresses.find((addr: IAddress) => addr.uid === address.uid) &&
                        address.saveForLater
                    ) {
                        state.addresses.push(address);
                    }
                }
            }
        });
    },
});

export const cartActions = cartSlice.actions;

export default cartSlice.reducer;
