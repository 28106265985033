import React from 'react';

import { IAddress } from 'marketplace-common/dist';

import styles from '../AddressSelect.module.scss';

const AddressItem = (props: {
    item?: IAddress;
    selected?: string;
    createNew?: () => void;
    selectAddress?: () => void;
}) => {
    const { item, selected } = props;
    let isSelected = false;
    const isCreate = !props.item;
    if (item) {
        isSelected = item.uid === selected;
    }

    return (
        <div className={`${styles.addressItem} ${isSelected ? styles.selected : ''}`}>
            {isCreate && <div onClick={props.createNew}>add new Address</div>}
            {!isCreate && item && (
                <div onClick={props.selectAddress}>
                    <p>{item.addressName}</p>
                    <p>
                        {item.street} {item.number}
                    </p>
                    <p>
                        {item.zip} {item.city}
                    </p>
                </div>
            )}
        </div>
    );
};

export default AddressItem;
