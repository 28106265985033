import ShippingForm from '../../Elements/ShippingForm/ShippingForm';
import { OAUTH } from '../../OAUTH';
import * as params from '../../configuration/params.json';
import { AppDispatch, IRootState } from '../../redux';
import { ISetShipping } from '../../redux/interfaces/ISetShipping';
import { uiActions } from '../../redux/slices/uiSlice';
import { userActions } from '../../redux/slices/userSlice';
import { addShippingAddress } from '../../redux/thunks/cartThunks';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    COMMANDS,
    IAddress,
    IStartMerchantRegistrationWizardCommand,
} from 'marketplace-common/dist';
import { v4 as uuid } from 'uuid';

import styles from './Userbar.module.scss';

import '../Navbar/Navbar.scss';

const Userbar = () => {
    const marketplaceUrl = `${params.MARKETPLACE_SERVER}${
        params.MARKETPLACE_SERVER.slice(-1) === '/' ? '' : '/'
    }user`;
    const { t, i18n } = useTranslation();
    const [showForm, setShowForm] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { isMerchant, user, shippingData } = useSelector((state: IRootState) => state.user);
    const avatar = user.avatar.replace('%size%', '80');
    const showShippingForm = () => {
        setShowForm((prevState) => !prevState);
    };
    const sendShippingInfo = (data: ISetShipping) => {
        dispatch(addShippingAddress(data));
    };
    useEffect(() => {
        setShowForm(false);
    }, [shippingData]);

    const handleLogout = async () => {
        OAUTH.logout(false);
        dispatch(userActions.setPublicUser());
        dispatch(uiActions.backDropClicked());
        const url = `${params.REDWOOD}logout`;
        const test = window.open(url, '_blank');
        setTimeout(() => {
            if (test) {
                test.close();
                document.location.reload();
            }
        }, 100);
    };
    const handleBecomeMerchant = async () => {
        const body: IStartMerchantRegistrationWizardCommand = {
            commandType: COMMANDS.StartMerchantRegistrationWizardCommand,
            uid: uuid(),
        };
        const response = await OAUTH.authRequest(marketplaceUrl, 'POST', { body });
        if (response?.result?.url) {
            // const w = window.open(response.data.metadata.accountLink.url);
            const w = window.open(response.result.url);
            if (w) {
                w.addEventListener('onbeforeunload', () => {});
            }
        }
    };

    return (
        <div className={`navbar ${styles.userbar}`}>
            <img src={avatar} alt={`avatar for ${user.username}`} />
            <h2>Welcome {user.first_name}</h2>
            {user.id !== -1 && <button onClick={handleLogout}>logout</button>}
            <button onClick={handleBecomeMerchant}>{isMerchant ? 'Edit' : 'Word'} aanbieder</button>
            {/*{!isMerchant && <button onClick={handleBecomeMerchant}>Word aanbieder</button>}*/}

            <h3>Shipping info</h3>
            {!showForm && (
                <button onClick={showShippingForm}>
                    {shippingData ? 'edit' : 'add'} shipping data
                </button>
            )}
            {!showForm && (
                <div>
                    <p>aanspreking: {shippingData?.salutation}</p>
                    <p>naam: {shippingData?.name}</p>
                    <p>
                        adres: {shippingData?.street} {shippingData?.number} {shippingData?.bus}
                    </p>
                    <p>
                        stad: {shippingData?.zip} {shippingData?.city}
                    </p>
                    <p>provincie: {shippingData?.province}</p>
                    <p>land: {shippingData?.countryISOCode}</p>
                </div>
            )}
            {showForm && (
                <ShippingForm
                    data={shippingData}
                    names={{ firstName: user.first_name, lastName: user.last_name }}
                    cancel={showShippingForm}
                    post={(data: ISetShipping) => sendShippingInfo(data)}
                />
            )}
        </div>
    );
};

export default Userbar;
