import { OAUTH } from '../../OAUTH';
import * as params from '../../configuration/params.json';
import { FILTER_GROUPS } from '../constants/FILTER_GROUPS';
import { LOCAL_STORAGES } from '../constants/LOCAL_STORAGES';
import { IRootState } from '../index';
import { IFilterOption } from '../interfaces/filters/IFilterOption';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { BOOK_EDITIONS, MERCHANT_TYPE, QUERIES } from 'marketplace-common/dist';
import { v4 as uuid } from 'uuid';

const marketplaceUrl = `${params.MARKETPLACE_SERVER}${
    params.MARKETPLACE_SERVER.slice(-1) === '/' ? '' : '/'
}`;

export const getFilters = createAsyncThunk<any, { state: IRootState }>(
    'filters/getFilters',
    async (_, { getState }) => {
        const { data } = getState();
        const iconTable = {
            [MERCHANT_TYPE.COMPANY]: undefined,
            [MERCHANT_TYPE.TEACHER]: ['fill-icon-briefcase-2'],
            [MERCHANT_TYPE.PUBLISHER]: ['fill-icon-building-2'],
            [MERCHANT_TYPE.NON_PROFIT_ORGANIZATION]: undefined,
            [MERCHANT_TYPE.INHOUSE]: undefined,
            [MERCHANT_TYPE.SCHOOL]: undefined,
            [BOOK_EDITIONS.PAPER]: ['fill-icon-book-6'],
            [BOOK_EDITIONS.PAPER_DIGITAL]: ['fill-icon-book-6', 'fill-icon-laptop-1'],
            [BOOK_EDITIONS.DIGITAL]: ['fill-icon-laptop-1'],
        };
        const storedFilters = localStorage.getItem(LOCAL_STORAGES.FILTERS);
        if (storedFilters) {
            return { data: { filters: JSON.parse(storedFilters) } };
        }
        const filters = [
            ...data.courses.map((course) => {
                return {
                    uid: uuid(),
                    type: course.code,
                    group: FILTER_GROUPS.COURSE,
                    name: `courses.${course.code}`,
                    value: false,
                    count: 0,
                };
            }),
            ...data.years.map((year) => {
                return {
                    uid: uuid(),
                    type: year,
                    group: FILTER_GROUPS.YEAR,
                    name: `years.${year.toString()}`,
                    value: false,
                    count: 0,
                };
            }),
            ...data.editions.map((edition) => {
                const item: IFilterOption = {
                    uid: uuid(),
                    type: edition,
                    group: FILTER_GROUPS.EDITION,
                    name: `editions.${edition}`,
                    value: false,
                    count: 0,
                };
                if (iconTable[edition]) {
                    item.icons = iconTable[edition];
                }
                return item;
            }),
            ...data.suppliers.map((supplier) => {
                const item: IFilterOption = {
                    uid: uuid(),
                    type: supplier,
                    group: FILTER_GROUPS.SUPPLIER,
                    name: `suppliers.${supplier}`,
                    value: false,
                    count: 0,
                };
                if (iconTable[supplier]) {
                    item.icons = iconTable[supplier];
                }
                return item;
            }),
        ];
        return { data: { filters } };
    }
);
