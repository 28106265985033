import { LOCAL_STORAGES } from '../constants/LOCAL_STORAGES';
import { ISortState } from '../interfaces/sort/ISortState';
import { createSlice } from '@reduxjs/toolkit';

let initialState: ISortState = {
    rating: { value: false, descending: true },
    // downloads: { value: false, descending: true },
    recent: { value: true, descending: true },
    abc: { value: false, descending: true },
};

const fromStorage = localStorage.getItem(LOCAL_STORAGES.SORT);
if (fromStorage) {
    initialState = JSON.parse(fromStorage);
}

const sortSlice = createSlice({
    name: 'sort',
    initialState,
    reducers: {
        selectSortParameter(state: ISortState, action) {
            Object.keys(state).forEach((option: string) => {
                const hasType = Object.keys(action.payload).includes('type');
                if (option === action.payload.method && !hasType) {
                    if (state[option].value) {
                        state[option].descending = !state[option].descending;
                    }
                    state[option].value = true;
                } else if (hasType && option === action.payload.method) {
                    state[option].value = true;
                    state[option].descending = action.payload.type
                }
                else {
                    state[option].value = false;
                }
            });
            localStorage.setItem(LOCAL_STORAGES.SORT, JSON.stringify(state));
        },
    },
});
export const sortActions = sortSlice.actions;

export default sortSlice.reducer;
