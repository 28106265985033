import Currency from '../../../Elements/Currency/Currency';
import { AppDispatch, IRootState } from '../../../redux';
import {
    addItemToCart,
    removeItemFromCart,
    updateItemAmount,
} from '../../../redux/thunks/cartThunks';

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { cloneDeep, debounce } from 'lodash';
import { BOOK_EDITIONS, IShoppingCartItem, PURCHASE_TYPE } from 'marketplace-common/dist';

import styles from './CartItem.module.scss';

const cartItem = (props: { item: IShoppingCartItem }) => {
    const { item } = props;
    const isPaper = item?.edition === BOOK_EDITIONS.PAPER;
    const dispatch = useDispatch<AppDispatch>();
    const [amount, setAmount] = useState(item.quantity);
    const amountRef = useRef<HTMLInputElement>(null);
    const { pending, items } = useSelector((state: IRootState) => state.cart);
    const singlePrice =
        item.price[
            item.purchaseType === PURCHASE_TYPE.SALE
                ? isPaper
                    ? 'paperPrice'
                    : 'salePrice'
                : 'leasePrice'
        ] || 0;
    const totalPrice = (singlePrice || 0) * item.quantity;
    useEffect(() => {
        setAmount(props.item.quantity);
    }, [props.item.quantity]);
    //todo correct debounce
    const handleAdd = () => {
        dispatch(updateItemAmount({ item, amount: item.quantity + 1 }));
    };
    const handleSubtract = () => {
        if (item.quantity === 1) {
            handleRemove();
        } else {
            dispatch(updateItemAmount({ item, amount: item.quantity - 1 }));
        }
    };
    const dispatchItemUpdate = () => {
        dispatch(updateItemAmount({ item, amount }));
    };
    const debouncedInput = () => {
        if (amountRef.current) {
            setAmount(+amountRef.current.value);
        }
        debounce(dispatchItemUpdate, 200);
    };
    const handleRemove = () => {
        dispatch(removeItemFromCart({ item }));
    };
    return (
        <div className={`${styles.cartItem} formStyle`}>
            <div className={styles.amountContainer}>
                <button onClick={handleSubtract} disabled={pending} className="warn">
                    -
                </button>
                {/*<input type="text" ref={amountRef} value={amount} onChange={debouncedInput} />*/}
                <span>{amount}</span>
                <button onClick={handleAdd} disabled={pending} className="success">
                    +
                </button>
            </div>
            <div className={styles.nameContainer}>
                <span>{item.productRef.productName}</span> <sup>{isPaper ? '(papier)' : ''} </sup>
            </div>
            <div className={styles.priceContainer}>
                <span>
                    <Currency>{totalPrice}</Currency>
                </span>
            </div>
            <div className={styles.removeContainer}>
                <button onClick={handleRemove} disabled={pending} className="danger">
                    <i className="fill-icon-bin-2" />
                </button>
            </div>
        </div>
    );
};

export default cartItem;
