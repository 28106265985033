import { OAUTH } from '../../OAUTH';
import * as params from '../../configuration/params.json';
import { getThumbnail } from '../../utilities/utilities';
import { IRootState } from '../index';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { COMMANDS_MARKETPLACE, IGetMySceneCommand, IScene } from 'maple-common/dist';
import {
    BOOK_EDITIONS,
    COMMANDS,
    IFindMarketplaceBundlesQuery,
    IFindMarketplaceRedwoodPublicationsQuery,
    IFindMarketplaceScenesQuery,
    IProduct,
    PRODUCT_TYPE,
    PURCHASE_TYPE,
    QUERIES,
} from 'marketplace-common/dist';
import { IOfferProductCommand } from 'marketplace-common/dist/commands/IOfferProductCommand';
import { IPublication } from 'redwood-model/dist';
import { v4 as uuid } from 'uuid';

import { loadMaterials, loadRedwoodPublications } from './storeContentThunks';

const mapleUrl = `${params.MAPLE_SERVER}${
    params.MAPLE_SERVER.slice(-1) === '/' ? '' : '/'
}api/marketplace`;
const marketplaceUrl = `${params.MARKETPLACE_SERVER}${
    params.MARKETPLACE_SERVER.slice(-1) === '/' ? '' : '/'
}`;

const commonProduct = (data: { [key: string]: any }, source?: IScene | IPublication | IProduct) => {
    const purchaseTypes = [];
    let salePrice = undefined;
    if (!isNaN(data.salePrice)) {
        salePrice = parseFloat(data.salePrice);
        purchaseTypes.push(PURCHASE_TYPE.SALE);
    }
    let leasePrice = undefined;
    if (!isNaN(data.leasePrice)) {
        leasePrice = parseFloat(data.leasePrice);
        purchaseTypes.push(PURCHASE_TYPE.LEASE);
    }
    if (typeof salePrice === 'undefined' && typeof leasePrice === 'undefined') {
        throw new Error('no price');
    }
    const cmd: IOfferProductCommand = {
        uid: uuid(),
        commandType: COMMANDS.OfferProductCommand,
        price: {
            salePrice,
            leasePrice,
        },
        productRef: {
            productTypes: [],
            productName: '',
        },
        description: data.description,
        tags: data.tags,
        purchaseTypes,
    };
    if (data.publications?.length) {
        cmd.licenses = data.publications.map((pub: { label: string; value: number }) => {
            return { name: pub.label, id: pub.value };
        });
    }
    if (data.course) {
        cmd.courseCode = data.course.value;
    }
    if (data.year) {
        cmd.year = data.year.value;
    }

    return cmd;
};

export const toggleAvailable = createAsyncThunk(
    'myItems/toggleAvailable',
    async (toggle: boolean, { dispatch }) => {
        dispatch(loadScenes({ listed: !toggle }));
        dispatch(loadMyRedwoodPublications({ listed: !toggle }));
        return toggle;
    }
);

export const loadMyRedwoodPublications = createAsyncThunk<
    any,
    { listed: boolean },
    { state: IRootState }
>('myItems/loadMyRedwoodPublications', async (data: { listed: boolean }, { getState }) => {
    const { user } = getState();

    const storeBody: IFindMarketplaceRedwoodPublicationsQuery = {
        queryType: QUERIES.FindMarketplaceRedwoodPublicationsQuery,
        uid: uuid(),
        size: 10,
        from: 0,
        stripe_account_id: user.merchantId,
    };
    const storeResponse = await OAUTH.authRequest(marketplaceUrl, 'POST', { body: storeBody });

    return { source: user.user.publications, store: storeResponse.result };
});
export const loadMyBundles = createAsyncThunk<any, { listed: boolean }, { state: IRootState }>(
    'myItems/loadMyBundles',
    async (data: { listed: boolean }, { getState }) => {
        const { user } = getState();

        const storeBody: IFindMarketplaceBundlesQuery = {
            queryType: QUERIES.FindMarketplaceBundlesQuery,
            uid: uuid(),
            size: 10,
            from: 0,
            stripe_account_id: user.merchantId,
        };
        const storeResponse = await OAUTH.authRequest(marketplaceUrl, 'POST', { body: storeBody });

        console.log({ storeResponse });
        // return { source: user.user.publications, store: storeResponse.result };
    }
);
export const loadScenes = createAsyncThunk<any, { listed: boolean }, { state: IRootState }>(
    'myItems/loadScenes',
    async (data: { listed: boolean }, { getState }) => {
        const { user } = getState();
        const sourceBody: IGetMySceneCommand = {
            type: COMMANDS_MARKETPLACE.GetMyScenes,
            payload: {
                skip: 0,
                limit: 50,
                listed: data.listed,
            },
        };
        const sourceResponse = await OAUTH.authRequest(mapleUrl, 'POST', { body: sourceBody });
        const storeBody: IFindMarketplaceScenesQuery = {
            queryType: QUERIES.FindMarketplaceScenesQuery,
            uid: uuid(),
            size: 10,
            from: 0,
            stripe_account_id: user.merchantId,
        };
        const storeResponse = await OAUTH.authRequest(marketplaceUrl, 'POST', { body: storeBody });

        return { source: sourceResponse.data, store: storeResponse.result };
    }
);
export const listBundle = createAsyncThunk(
    'myItems/listBundle',
    async (payload: { data: { [key: string]: any } }) => {
        const { data } = payload;
        const cmd: IOfferProductCommand = commonProduct(data);

        //todo add bundle to PRODUCT_TYPE
        cmd.productRef = {
            productName: data.name,
            productTypes: [PRODUCT_TYPE.BUNDLE || 'BUNDLE'],
        };
        cmd.thumbUrl = getThumbnail();
        const result = await OAUTH.authRequest(marketplaceUrl, 'POST', {
            body: cmd,
        });
        return result;
    }
);

export const listScene = createAsyncThunk(
    //todo list with full data
    'myItems/listScene',
    async (payload: { scene: IScene; data: { [key: string]: any } }, { dispatch }) => {
        const { scene, data } = payload;
        try {
            if (!scene || !scene.id || !scene.name) {
                throw new Error('no scene');
            }
            const cmd: IOfferProductCommand = commonProduct(data, scene);

            cmd.thumbUrl = getThumbnail(scene);
            cmd.productRef = {
                scene_uid: scene.id.toString(),
                productTypes: [PRODUCT_TYPE.MAPLE],
                productName: data.name,
            };

            const cmdResult = await OAUTH.authRequest(marketplaceUrl, 'POST', {
                body: cmd,
            });
            const { leasePrice, salePrice } = cmd.price;

            if (cmdResult) {
                dispatch(loadMaterials());
                const updateMapleCmd = {
                    type: COMMANDS_MARKETPLACE.UpdateMarketplaceScene,
                    payload: {
                        sceneId: scene.id,
                        forLease: typeof leasePrice !== 'undefined' ? leasePrice : false,
                        forSale: typeof salePrice !== 'undefined' ? leasePrice : false,
                    },
                };
                const response = await OAUTH.authRequest(mapleUrl, 'POST', {
                    body: updateMapleCmd,
                });

                return response.data;
            }
            throw new Error('failed');
        } catch (e) {
            console.error(e);
            return scene;
        }
    }
);

export const listPublication = createAsyncThunk(
    //todo list with complete data
    'myItems/listPublication',
    async (payload: { publication: IPublication; data: { [key: string]: any } }, { dispatch }) => {
        const { publication, data } = payload;
        try {
            if (!publication || !publication.id || !publication.name) {
                throw new Error('no publication');
            }

            const cmd: IOfferProductCommand = commonProduct(data, publication);
            const { salePrice, leasePrice } = cmd.price;
            const { purchaseTypes } = cmd;

            let paperPrice = undefined;
            let weight = undefined;
            if (!isNaN(data.paperPrice)) {
                paperPrice = parseFloat(data.paperPrice);
                weight = +data.weight;
            }
            let edition = undefined;
            if ((leasePrice || salePrice) && !paperPrice) {
                edition = BOOK_EDITIONS.DIGITAL;
            }
            if ((leasePrice || salePrice) && paperPrice) {
                edition = BOOK_EDITIONS.PAPER_DIGITAL;
            }
            if (!(leasePrice || salePrice) && paperPrice) {
                edition = BOOK_EDITIONS.PAPER;
            }

            if (
                typeof salePrice === 'undefined' &&
                typeof leasePrice === 'undefined' &&
                typeof paperPrice === 'undefined'
            ) {
                throw new Error('no price');
            }
            if (!!data.paperPrice && !weight) {
                throw new Error('now weight for paperprice');
            }
            if (!edition) {
                throw new Error('no edition set');
            }

            cmd.productRef = {
                publication_id: publication.id,
                productTypes: [PRODUCT_TYPE.REDWOOD],
                productName: data.name,
            };
            cmd.price = {
                salePrice,
                leasePrice,
                paperPrice,
            };
            cmd.purchaseTypes = purchaseTypes;

            if (edition) {
                cmd.book = {
                    edition,
                    weight,
                };
            }
            const cmdResult = await OAUTH.authRequest(marketplaceUrl, 'POST', {
                body: cmd,
            });

            if (cmdResult) {
                dispatch(loadRedwoodPublications());
            }
        } catch (e) {
            console.error(e);
            return publication;
        }
    }
);
