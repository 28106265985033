
import React, { ReactNode, useState } from 'react';

import { IProduct, PURCHASE_TYPE } from 'marketplace-common/dist';


import styles from './ProductDetail.module.scss';
import placeholderScene from '../../assets/images/placeholder-scene.jpg';
import placeholderPub from '../../assets/images/placeholder-pub.png';

import { useTranslation } from 'react-i18next';
import ShopButtonSec from '../ShopButton/ShopButtonSec';
import { addItemToCart } from '../../redux/thunks/cartThunks';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, IRootState } from '../../redux';

const ProductDetail = (props: {
    product: IProduct,
    salePrice?: number,
    leasePrice?: number,
    paperPrice?: number,
    merchant?:{ name: string, type: string },
    course?: string,
    type?: string,
    licenses?: ReactNode
}) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { pending } = useSelector((state: IRootState) => state.cart);

    const { product, salePrice, leasePrice, paperPrice, merchant, course, licenses, type }  = props;
    const productType = type || 'default';
    const placeholder = productType === 'book' ? placeholderPub : placeholderScene
    console.log({product});
    const addToCart = (type: string) => {

        if (!pending) {
            dispatch(
                addItemToCart({
                    item: product,
                    type,
                    quantity: 1,
                })
            );
        }
    };

    return (
        <div className={styles.detail}>
            <div className={styles.imageContainer}>
                <img
                    className={styles[productType]}
                    src={product.thumbUrl || placeholder}
                    alt={`${product.productRef.productName}-thumbnail`}
                    loading="lazy"
                />
            </div>
            <div className={styles.titleContainer}>
                <span>
                     {course && (
                         <span>
                        {course && <span className={styles.capitalize}>{course}</span>}
                             {product.year && <span> {product.year}</span>}
                    </span>
                     )}
                </span>
                <span>
                        {merchant && (
                            <span>
                                {merchant.name} ({merchant.type})
                            </span>
                        )}
                </span>
            </div>
            <div className={styles.extraContainer}>
                    <span>
                        {licenses && (
                            <span>
                                <span className={styles.title}>{t('item.linked')} </span>
                                {licenses}
                            </span>
                        )}
                    </span>

            </div>
            <div className={styles.dataContainer}>
                {product.description && <p className={styles.description}>{product.description}</p>}
                <div className={styles.infoContainer}>
                    {product.tags && (
                        <p>
                            <span className={styles.title}>{t('item.tags')} </span>
                            {product.tags}
                        </p>
                    )}

                    {licenses && (
                        <p>
                            <span className={styles.title}>{t('item.linked')} </span>
                            {licenses}
                        </p>
                    )}
                </div>
                <div className={styles.actionsContainer}>
                    {salePrice && salePrice >= 0 && (
                        <ShopButtonSec
                            clickHandler={() => addToCart(PURCHASE_TYPE.SALE)}
                            price={salePrice}
                            type="digital"
                        />
                    )}
                    {leasePrice && leasePrice >= 0 && (
                        <ShopButtonSec
                            clickHandler={() => addToCart(PURCHASE_TYPE.LEASE)}
                            price={leasePrice}
                            type="lease"
                        />
                    )}
                    {paperPrice && paperPrice >= 0 && (
                    <ShopButtonSec
                        clickHandler={() => addToCart(PURCHASE_TYPE.LEASE)}
                        price={paperPrice}
                        type="paper"
                    />
                )}
                </div>
            </div>
        </div>
    );
}
export default ProductDetail;
