import React from 'react';

import styles from './ErrorPages.module.scss';

const Error403 = () => {
    return (
        <div className={styles.error}>
            <p>403 permission denied</p>
        </div>
    );
};

export default Error403;
