import Currency from '../../../Elements/Currency/Currency';
import { AppDispatch, IRootState } from '../../../redux';
import { clearCart, purchaseCart } from '../../../redux/thunks/cartThunks';
import Cartitem from '../CartItem/CartItem';

import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LOGGER } from 'educateme-oauth/dist/constants';
import {
    BOOK_EDITIONS,
    COMMANDS,
    IShoppingCartItem,
    PURCHASE_TYPE,
    QUERIES,
} from 'marketplace-common/dist';
import { v4 as uuid } from 'uuid';

import styles from '../Cartbar.module.scss';

const Step1 = (props: { changeStep: (num: number, payload: { totalCart: number }) => void }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t, i18n } = useTranslation();
    const { items, totalPurchase, totalLease, totalPhysical, pending, shipping } = useSelector(
        (state: IRootState) => state.cart
    );
    const saleItemsArray = items.filter(
        (item) =>
            item.purchaseType === PURCHASE_TYPE.SALE &&
            item.quantity > 0 &&
            item.edition !== BOOK_EDITIONS.PAPER
    );
    const physicalItemsArray = items.filter(
        (item) =>
            item.purchaseType === PURCHASE_TYPE.SALE &&
            item.quantity > 0 &&
            item.edition === BOOK_EDITIONS.PAPER
    );
    const leaseItemsArray = items.filter(
        (item) => item.purchaseType === PURCHASE_TYPE.LEASE && item.quantity > 0
    );
    let saleItems: JSX.Element | JSX.Element[] = <p>{t('cart.no-purchases')}</p>;
    let leaseItems: JSX.Element | JSX.Element[] = <p>{t('cart.no-leases')}</p>;
    let physicalItems: JSX.Element | JSX.Element[] = <p>{t('cart.no-leases')}</p>;
    if (saleItemsArray?.length > 0) {
        saleItems = saleItemsArray.map((item: IShoppingCartItem, index) => {
            return (
                <Fragment key={item.productRef.productName + item.purchaseType + index}>
                    <Cartitem item={item} />
                </Fragment>
            );
        });
    }
    if (leaseItemsArray?.length > 0) {
        leaseItems = leaseItemsArray.map((item: IShoppingCartItem, index) => {
            return (
                <Fragment key={item.productRef.productName + item.purchaseType + index}>
                    <Cartitem item={item} />
                </Fragment>
            );
        });
    }
    if (physicalItemsArray?.length > 0) {
        physicalItems = physicalItemsArray.map((item: IShoppingCartItem, index) => {
            return (
                <Fragment key={item.productRef.productName + item.purchaseType + index}>
                    <Cartitem item={item} />
                </Fragment>
            );
        });
    }
    const handleClear = () => {
        dispatch(clearCart());
    };

    const handleLicense = () => {
        dispatch(purchaseCart({ licenses: true }));
    };
    const handleSale = () => {
        if (physicalItemsArray?.length > 0) {
            props.changeStep(2, { totalCart });
            return;
        }
        dispatch(purchaseCart({ licenses: false }));
    };
    const totalCart = totalPurchase + totalPhysical;

    return (
        <div>
            <div className={styles.titleContainer}>
                <h2>Winkelwagen</h2>
                {items.length > 0 && (
                    <button disabled={pending} onClick={handleClear} className="danger">
                        {t('cart.clear')}
                    </button>
                )}
            </div>
            {(saleItemsArray.length > 0 || physicalItemsArray.length > 0) && <h3>Aankopen</h3>}
            <div className={styles.items}>
                {saleItemsArray.length === 0 &&
                    leaseItemsArray.length === 0 &&
                    physicalItemsArray.length === 0 && <p>{t('cart.empty')}</p>}
                {saleItemsArray.length > 0 && (
                    <>
                        <h4>{t('cart.digital')}</h4>
                        <div className={styles.purchases}>{saleItems}</div>
                    </>
                )}
                {physicalItemsArray.length > 0 && (
                    <>
                        <h4>{t('cart.physical')}</h4>
                        <div className={styles.physical}>{physicalItems}</div>
                    </>
                )}
            </div>
            <div className={styles.summaries}>
                {(saleItemsArray.length > 0 || physicalItemsArray.length > 0) && (
                    <div className={styles.subTotal}>
                        <div>
                            <span>{t('cart.total-complete')}</span>{' '}
                            <span>
                                <Currency>{totalPurchase + totalPhysical}</Currency>
                            </span>
                        </div>
                    </div>
                )}
            </div>
            {(saleItemsArray.length > 0 || physicalItemsArray.length > 0) && (
                <div className={styles.cartActions}>
                    <button disabled={pending} onClick={handleSale}>
                        {t('cart.processSale')}
                    </button>
                </div>
            )}
            {leaseItemsArray.length > 0 && (
                <>
                    <h3>{t('cart.leases')}</h3>
                    <div className={styles.items}>
                        <div className={styles.leases}>{leaseItems}</div>
                    </div>
                    <div className={styles.summaries}>
                        {leaseItemsArray.length > 0 && (
                            <div className={styles.subTotal}>
                                <div>
                                    <span>{t('cart.total-leases')}</span>{' '}
                                    <span>
                                        <Currency>{totalLease}</Currency>
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
            {leaseItemsArray.length > 0 && (
                <div className={styles.cartActions}>
                    <button disabled={pending} onClick={handleLicense}>
                        {t('cart.processLease')}
                    </button>
                </div>
            )}
        </div>
    );
};

export default Step1;
