import { AppDispatch, IRootState } from '../../../../redux';
import { myItemsActions } from '../../../../redux/slices/myItemsSlice';
import { useEffectOnce } from '../../../../utilities/customHooks/useEffectOnce';
import OverviewForm from '../../Overview/Form/OverviewForm';
import MyItemsOverview from '../../Overview/MyItemsOverview';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IScene } from 'maple-common/dist';
import { IProduct } from 'marketplace-common/dist';
import { IPublication } from 'redwood-model/dist';

const MyPublications = () => {
    const { selected, source, store, showListed } = useSelector(
        (state: IRootState) => state.myItems
    );
    const { publications: sourcePublications } = source;
    const { publications: storePublications } = store;
    const publications = showListed ? storePublications : sourcePublications;

    const dispatch = useDispatch<AppDispatch>();
    // useEffectOnce(() => {
    // })

    const selectHandler = (item: IPublication | IScene | IProduct, type = 'publications') => {
        dispatch(myItemsActions.setSelected(item));
    };

    return (
        <section className="overviewCommon">
            <MyItemsOverview list={publications} type="book" selectItem={selectHandler} />
            {selected && <OverviewForm item={selected} type="book" />}
        </section>
    );
};

export default MyPublications;
