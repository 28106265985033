import { IUserState } from '../interfaces/IUserState';
import { getCurrentUser } from '../thunks/userThunks';
import { createSlice } from '@reduxjs/toolkit';

import { PUBLIC_USER } from 'marketplace-common/dist';

const initialState: IUserState = {
    user: PUBLIC_USER,
    isMerchant: false,
    shippingData: undefined,
    triedGettingUser: false,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loadShipping(state, action) {
            const { address } = action.payload.data;
            state.shippingData = address;
        },
        setPublicUser(state) {
            Object.assign(state, initialState);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCurrentUser.fulfilled, (state, action) => {
            const { user, isMerchant, merchantId } = action.payload.data;
            state.user = user;
            if (isMerchant && merchantId) {
                state.isMerchant = isMerchant;
                state.merchantId = merchantId;
            }
            state.triedGettingUser = true;
        });
    },
});
export const userActions = userSlice.actions;

export default userSlice.reducer;
