import { OAUTH } from '../../OAUTH';
import * as params from '../../configuration/params.json';
import { AppDispatch } from '../../redux';
import { uiActions } from '../../redux/slices/uiSlice';

import React from 'react';
import { useDispatch } from 'react-redux';

import { COMMANDS_MARKETPLACE, IGetMySceneCommand } from 'maple-common/dist';

import styles from './Bundles.module.scss';

const Bundles = () => {
    return (
        <div className={styles.bundles}>
            <div>bundles</div>
        </div>
    );
};

export default Bundles;
