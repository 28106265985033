import Currency from '../../../../Elements/Currency/Currency';
import Rating from '../../../../Elements/Rating/Rating';
import { AppDispatch, IRootState } from '../../../../redux';
import { postRating } from '../../../../redux/thunks/purchaseThunks';

import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IPurchasedItem, PRODUCT_TYPE, PURCHASE_TYPE } from 'marketplace-common/dist';

import styles from './PurchasedItem.module.scss';

const PurchasedItem = (props: { item: IPurchasedItem }) => {
    const { item } = props;
    const dispatch = useDispatch<AppDispatch>();
    const { user } = useSelector((state: IRootState) => state.user);
    const [showRatingForm, setShowRatingForm] = useState(false);
    const [rating, setRating] = useState(0);
    const textInput: React.RefObject<HTMLTextAreaElement> = useRef(null);
    const isPaper =
        item.productRef.productTypes.includes(PRODUCT_TYPE.REDWOOD || PRODUCT_TYPE.HEMLOCK) &&
        item.purchaseType === PURCHASE_TYPE.SALE;
    const price =
        (isPaper
            ? item.price.paperPrice
            : item.purchaseType === PURCHASE_TYPE.SALE
            ? item.price.salePrice
            : item.price.leasePrice) || 0;
    const currentRating = item.rating?.find((r) => r.user_id === user.id);
    const reviewHandler = () => {
        setShowRatingForm((prev) => !prev);
    };
    const handleSetRating = (rating: number) => {
        setRating(rating);
    };
    const onSubmitHandler = () => {
        dispatch(
            postRating({
                productRef: item.productRef,
                text: textInput?.current?.value || '',
                stars: rating,
                uid: currentRating?.uid,
            })
        );
    };

    return (
        <div className={styles.purchasedItem}>
            <div className={styles.main}>
                <span>{item.quantity}</span>
                <h4>{item.productRef.productName}</h4>
                <span>
                    <Currency>{price * item.quantity}</Currency>
                    <Rating value={currentRating?.stars || 0} readonly={true} />
                </span>
                <button onClick={reviewHandler}>review</button>
            </div>
            {showRatingForm && (
                <div>
                    <Rating setRating={handleSetRating} value={0} />
                    <textarea ref={textInput} />
                    <button onClick={onSubmitHandler}>submit</button>
                </div>
            )}
        </div>
    );
};

export default PurchasedItem;
