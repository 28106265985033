import { OAUTH } from '../../OAUTH';
import * as params from '../../configuration/params.json';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    COMMANDS,
    ICheckUserIsMerchantQuery,
    IStartMerchantRegistrationWizardCommand,
    PUBLIC_USER,
    QUERIES,
    SERVER_ERROR_CODES,
} from 'marketplace-common/dist';
import { v4 as uuid } from 'uuid';

const userUrl = `${params.API}${params.API.slice(-1) === '/' ? '' : '/'}user`;
const marketplaceUrl = `${params.MARKETPLACE_SERVER}${
    params.MARKETPLACE_SERVER.slice(-1) === '/' ? '' : '/'
}`;

export const getCurrentUser = createAsyncThunk('user/getCurrentUser', async () => {
    const authenticated = await OAUTH.getOAuthInfo();
    if (authenticated.token === PUBLIC_USER['TOKEN']) {
        return { data: { user: PUBLIC_USER, isMerchant: false, merchantId: undefined } };
    }

    const body: IStartMerchantRegistrationWizardCommand = {
        commandType: COMMANDS.StartMerchantRegistrationWizardCommand,
        uid: uuid(),
    };
    const getUser = OAUTH.authRequest(userUrl, 'GET');
    const getMerchant = OAUTH.authRequest(marketplaceUrl, 'POST', { body });
    const [user, merchant] = await Promise.all([getUser, getMerchant]);

    const response = {
        data: {
            user,
            isMerchant: !!(merchant.code === SERVER_ERROR_CODES.ALREADY_REGISTERED_AS_MERCHANT),
            merchantId: undefined,
        },
    };
    if (response.data.isMerchant) {
        response.data.merchantId = merchant.data.id;
    }
    return response;
});
