import { OAUTH } from '../../OAUTH';
import { AppDispatch, IRootState } from '../../redux';
import { uiActions } from '../../redux/slices/uiSlice';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import imgUrl from '../../assets/images/e-ducate.me white.svg';

import Cart from './Cart/Cart';
import Search from './Search/Search';
import Sort from './Sort/Sort';
import styles from './Titlebar.module.scss';

const Titlebar = () => {
    const { search: searchVisible, sort: sortVisible } = useSelector(
        (state: IRootState) => state.ui
    );
    const { user } = useSelector((state: IRootState) => state.user);

    const dispatch = useDispatch<AppDispatch>();
    const openUser = () => {
        dispatch(uiActions.toggleUser());
    };
    const handleLogin = () => {
        const url = OAUTH.manualLogin();

        window.location.href = url;
    };
    const userClickHandler = () => {
        if (user.id !== -1) {
            dispatch(uiActions.toggleUser());
            return;
        }
        const url = OAUTH.manualLogin();
        window.location.href = url;
    };
    const toggleFilter = () => {
        dispatch(uiActions.toggleFilters());
    }

    return (
        <div className={styles.titlebar}>
            <div className={styles.titleContainer}>
                <img src={imgUrl} alt="educate-me" />
                <h1>Marketplace</h1>
            </div>
            <div className={styles.midContainer}>
                {sortVisible && <Sort className={styles.sortParent} type='top' />}
                {searchVisible && <Search className={styles.searchParent} type='top' />}
            </div>

            {user.id !== -1 && <Cart />}

            <div className={styles.iconsContainer}>
                <div className={`${styles.iconContainer} ${styles.filter}`} onClick={toggleFilter}><i className="fill-icon-filter" /></div>
                <div className={styles.iconContainer} onClick={userClickHandler}><i className="icon-profile-1" /></div>
            </div>
        </div>
    );
};

export default Titlebar;
7
