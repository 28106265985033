import { AppDispatch } from '../../../redux';
import { IFilterOption } from '../../../redux/interfaces/filters/IFilterOption';
import { filterActions } from '../../../redux/slices/filterSlice';

import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './FilterGroup.module.scss';
import Option from './Option/Option';

const FilterGroup = (props: { name: string; options: IFilterOption[] }) => {
    const dispatch = useDispatch<AppDispatch>();
    const clickHandler = (event: Event, id: string) => {
        event.preventDefault();
        dispatch(filterActions.changeFilter(id));
    };

    return (
        <div className={styles.filterGroup}>
            <div className={styles.header}>
                <h2>{props.name}</h2>
            </div>
            <div className={styles.options}>
                {props.options.map((option, index) => {
                    const key = `${option.name}-${index}`;
                    return (
                        <Option
                            onClick={(event: Event) => clickHandler(event, option.uid)}
                            {...option}
                            key={key}
                            identifier={key}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default FilterGroup;
