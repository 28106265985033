import { OAUTH } from '../../OAUTH';
import * as params from '../../configuration/params.json';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    COMMANDS,
    IAddress,
    ICancelSubscriptionCommand,
    IFindMyPurchasesQuery,
    IFindMySubscriptionsQuery,
    IProductRef,
    IPurchase,
    IUpsertRatingCommand,
    IUpsertShipToAddressCommand,
    PUBLIC_USER,
    QUERIES,
    SERVER_ERROR_CODES,
} from 'marketplace-common/dist';
import { v4 as uuid } from 'uuid';

const url = `${params.MARKETPLACE_SERVER}${params.MARKETPLACE_SERVER.slice(-1) === '/' ? '' : '/'}`;

export const getPurchases = createAsyncThunk('purchase/getPurchases', async () => {
    const body: IFindMyPurchasesQuery = {
        queryType: QUERIES.FindMyPurchasesQuery,
        uid: uuid(),
        size: 10,
        from: 0,
    };

    const response: { result: IPurchase[] } = await OAUTH.authRequest(url, 'POST', { body });

    return { data: { purchases: response.result } };
});
export const getLicenses = createAsyncThunk('purchase/getLicenses', async () => {
    const body: IFindMySubscriptionsQuery = {
        queryType: QUERIES.FindMySubscriptionsQuery,
        uid: uuid(),
        size: 30,
        from: 0,
    };

    const response: {
        result: { id: string; subscription: string; metadata: { productRef: IProductRef } }[];
    } = await OAUTH.authRequest(url, 'POST', { body });

    return { data: { licenses: response.result } };
});
export const postRating = createAsyncThunk(
    'purchase/setRating',
    async (payload: { productRef: IProductRef; text: string; stars: number; uid?: string }) => {
        const body: IUpsertRatingCommand = {
            commandType: COMMANDS.UpsertRatingCommand,
            uid: uuid(),
            iProductRef: payload.productRef,
            iRating: {
                uid: payload.uid || uuid(),
                text: payload.text,
                stars: payload.stars,
            },
        };

        const response = await OAUTH.authRequest(url, 'POST', { body });
    }
);
export const cancelSubscription = createAsyncThunk(
    'purchase/cancelSubscription',
    async (payload: { uid: string }) => {
        const body: ICancelSubscriptionCommand = {
            commandType: COMMANDS.CancelSubscriptionCommand,
            uid: payload.uid,
        };

        const response = await OAUTH.authRequest(url, 'POST', { body });
        console.log(response);

        //todo empty object response
    }
);
