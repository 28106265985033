import { ISetShipping } from '../../redux/interfaces/ISetShipping';
import { preventEnter as preventEnterCommon } from '../../utilities/utilities';

import React, { useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IAddress } from 'marketplace-common/dist';

import styles from './ShippingForm.module.scss';

const ShippingForm = (props: {
    data?: IAddress;
    names: { firstName: string; lastName: string };
    cancel: () => void;
    post: (data: ISetShipping) => void;
}) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        trigger,
        reset,
        formState: { errors },
    } = useForm({
        // shouldUseNativeValidation: true
    });
    const { t, i18n } = useTranslation();
    useEffect(() => {
        if (props.data) {
            reset(undefined, { keepDirty: false });
            setValue('salutation', props.data.salutation);
            if (props.names) {
                setValue('firstName', props.names.firstName);
                setValue('lastName', props.names.lastName);
            }
            setValue('companyName', '');
            setValue('street', props.data.street);
            setValue('number', props.data.number);
            setValue('bus', props.data.bus);
            setValue('zip', props.data.zip);
            setValue('city', props.data.city);
            setValue('province', props.data.province);
            setValue('countryISOCode', props.data.countryISOCode);
            setValue('province', props.data.province);
        }
    }, [props.data, props.names]);
    const saveValue = useWatch({ control, name: 'saveForLater' });

    const onsubmit = (data: any) => {
        const output: ISetShipping = Object.assign({} as ISetShipping, data);
        props.post(output);
    };
    const preventEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        trigger();
        preventEnterCommon(event);
    };

    return (
        <form onSubmit={handleSubmit(onsubmit)} className={`navbar ${styles.shippingForm}`}>
            <div className={styles.inputRow}>
                {/*salutation*/}
                <div className={`${styles.inputGroup} ${styles.small}`}>
                    <label htmlFor="salutation">{t('user.shipping.salutation')}</label>
                    <input
                        className={`${errors.salutation ? styles.error : ''}`}
                        onKeyDown={(e) => preventEnter(e)}
                        id="salutation"
                        {...register('salutation', {})}
                    />
                </div>
                {/*firstName*/}
                <div className={`${styles.inputGroup} ${styles.normal}`}>
                    <label htmlFor="firstName">{t('user.shipping.first-name')}</label>
                    <input
                        className={`${errors.firstName ? styles.error : ''}`}
                        onKeyDown={(e) => preventEnter(e)}
                        id="firstName"
                        {...register('firstName', {})}
                    />
                </div>
                {/*lastName*/}
                <div className={`${styles.inputGroup} ${styles.normal}`}>
                    <label htmlFor="lastName">{t('user.shipping.last-name')}</label>
                    <input
                        className={`${errors.lastName ? styles.error : ''}`}
                        onKeyDown={(e) => preventEnter(e)}
                        id="lastName"
                        {...register('lastName', {})}
                    />
                </div>
            </div>
            {/*companyName*/}
            <div className={styles.inputGroup}>
                <label htmlFor="companyName">{t('user.shipping.company')}</label>
                <input
                    className={`${errors.companyName ? styles.error : ''}`}
                    onKeyDown={(e) => preventEnter(e)}
                    id="companyName"
                    {...register('companyName', {})}
                />
            </div>
            <div className={styles.inputRow}>
                {/*street*/}
                <div className={styles.inputGroup}>
                    <label htmlFor="street">{t('user.shipping.street')}</label>
                    <input
                        className={`${errors.street ? styles.error : ''}`}
                        onKeyDown={(e) => preventEnter(e)}
                        id="street"
                        {...register('street', {})}
                    />
                </div>
                {/*number*/}
                <div className={styles.inputGroup}>
                    <label htmlFor="number">{t('user.shipping.number')}</label>
                    <input
                        className={`${errors.number ? styles.error : ''}`}
                        onKeyDown={(e) => preventEnter(e)}
                        id="number"
                        {...register('number', {})}
                    />
                </div>
                {/*bus*/}
                <div className={styles.inputGroup}>
                    <label htmlFor="bus">{t('user.shipping.bus')}</label>
                    <input
                        className={`${errors.bus ? styles.error : ''}`}
                        onKeyDown={(e) => preventEnter(e)}
                        id="bus"
                        {...register('bus', {})}
                    />
                </div>
            </div>
            <div className={styles.inputRow}>
                {/*zip*/}
                <div className={styles.inputGroup}>
                    <label htmlFor="zip">{t('user.shipping.zip')}</label>
                    <input
                        className={`${errors.zip ? styles.error : ''}`}
                        onKeyDown={(e) => preventEnter(e)}
                        id="zip"
                        {...register('zip', {})}
                    />
                </div>
                {/*city*/}
                <div className={styles.inputGroup}>
                    <label htmlFor="city">{t('user.shipping.city')}</label>
                    <input
                        className={`${errors.city ? styles.error : ''}`}
                        onKeyDown={(e) => preventEnter(e)}
                        id="city"
                        {...register('city', {})}
                    />
                </div>
            </div>
            <div className={styles.inputRow}>
                {/*province*/}
                <div className={styles.inputGroup}>
                    <label htmlFor="province">{t('user.shipping.province')}</label>
                    <input
                        className={`${errors.province ? styles.error : ''}`}
                        onKeyDown={(e) => preventEnter(e)}
                        id="province"
                        {...register('province', {})}
                    />
                </div>
                {/*country*/}
                <div className={styles.inputGroup}>
                    <label htmlFor="countryISOCode">{t('user.shipping.country')}</label>
                    <input
                        className={`${errors.countryISOCode ? styles.error : ''}`}
                        onKeyDown={(e) => preventEnter(e)}
                        id="countryISOCode"
                        {...register('countryISOCode', {})}
                    />
                </div>
                <div className={styles.inputGroup}>
                    <label htmlFor="extraInfo">{t('user.shipping.extra')}</label>
                    <textarea
                        className={`${errors.extraInfo ? styles.error : ''}`}
                        id="extraInfo"
                        {...register('extraInfo', {})}
                    />
                </div>
                <div className={styles.inputGroup}>
                    <label htmlFor="saveForLater">{t('user.shipping.save')}</label>
                    <input type="checkbox" id="saveForLater" {...register('saveForLater', {})} />
                    {saveValue && (
                        <input
                            className={`${errors.addressName ? styles.error : ''}`}
                            onKeyDown={(e) => preventEnter(e)}
                            id="addressName"
                            {...register('addressName', {})}
                        />
                    )}
                </div>
            </div>
            <br />
            <input type="submit" value={t('user.shipping.submit')} />
            <button onClick={props.cancel}>cancel</button>
        </form>
    );
};

export default ShippingForm;
