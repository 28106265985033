import React from 'react';
import { useTranslation } from 'react-i18next';

import { IScene } from 'maple-common/dist';
import { IPublication } from 'redwood-model/dist';

import styles from './MyItemsOverviewItem.module.scss';

export interface IOverviewItem {
    element: IScene | IPublication;
    type: 'book' | 'file' | 'material' | 'exercise' | 'bundle';
    selected: boolean;
    onSelect: () => void;
    thumbnail?: string;
}

const MyItemsOverviewItem = (props: IOverviewItem) => {
    const isPublication = props.type === 'book';
    const { element, type, selected, thumbnail, onSelect } = props;
    const { t, i18n } = useTranslation();
    const placeholder =
        'https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png?w=640';
    return (
        <div className={`${styles.item} ${selected ? styles.active : ''}`} onClick={onSelect}>
            <img src={thumbnail || placeholder} alt={element.name} />
            <section>
                <h4>{!isPublication ? element.name : element.slug}</h4>
                <p>
                    {(!isPublication ? element.description : '') ||
                        t('common.scene.missing-description')}
                </p>
            </section>
        </div>
    );
};

export default MyItemsOverviewItem;
