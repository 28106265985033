import { AppDispatch, IRootState } from '../../redux';

import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Cartbar.module.scss';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';

const Cartbar = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [currentStep, setStep] = useState(1);
    const [cartTotal, setCartTotal] = useState(0);
    const stepChange = (goTo: number, payload?: { totalCart: number }) => {
        if (payload) {
            setCartTotal(payload.totalCart);
        }
        setStep(goTo);
    };

    return (
        <div className={styles.container}>
            {currentStep === 1 && <Step1 changeStep={stepChange} />}
            {currentStep === 2 && <Step2 changeStep={stepChange} cartTotal={cartTotal} />}
        </div>
    );
};

export default Cartbar;
