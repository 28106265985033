import { OAUTH } from '../../OAUTH';
import * as params from '../../configuration/params.json';
import { IRootState } from '../index';
import { ISetShipping } from '../interfaces/ISetShipping';
import { uiActions } from '../slices/uiSlice';
import { userActions } from '../slices/userSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { LOGGER } from 'educateme-oauth/dist/constants';
import i18n from 'i18next';
import {
    BOOK_EDITIONS,
    COMMANDS,
    IAddItemsToShoppingCartCommand,
    IEditItemsInShoppingCartCommand,
    IFindMyStoredAddressesQuery,
    IGetShoppingCartQuery,
    IProduct,
    IPurchaseShoppingCartCommand,
    IRemoveItemsFromShoppingCartCommand,
    IShoppingCartItem,
    IUpsertShipToAddressCommand,
    PAYMENT_TYPE,
    PRODUCT_TYPE,
    PURCHASE_TYPE,
    QUERIES,
} from 'marketplace-common/dist';
import { v4 as uuid } from 'uuid';

const url = `${params.MARKETPLACE_SERVER}${params.MARKETPLACE_SERVER.slice(-1) === '/' ? '' : '/'}`;

export const queryCart = createAsyncThunk('cart/queryCart', async (_, { dispatch }) => {
    const body: IGetShoppingCartQuery = {
        uid: uuid(),
        queryType: QUERIES.GetShoppingCartQuery,
    };
    const response = await OAUTH.authRequest(url, 'POST', { body });
    if (response.result) {
        dispatch(userActions.loadShipping({ data: response.result }));
        return { data: response.result };
    }
});
export const addItemToCart = createAsyncThunk(
    'cart/addItemToCart',
    async (
        payload: { item: IProduct | IShoppingCartItem; type: string; quantity: number },
        { dispatch }
    ) => {
        const item: IShoppingCartItem = {
            uid: uuid(),
            productRef: payload.item.productRef,
            price: payload.item.price,
            purchaseType: payload.type,
            quantity: payload.quantity,
        };
        if (
            payload.item.productRef.productTypes.includes(
                PRODUCT_TYPE.REDWOOD || PRODUCT_TYPE.HEMLOCK
            )
        ) {
            item.edition = BOOK_EDITIONS.DIGITAL;
            if (payload.type === BOOK_EDITIONS.PAPER) {
                item.edition = BOOK_EDITIONS.PAPER;
                item.purchaseType = PURCHASE_TYPE.SALE;
            }
        }

        const body: IAddItemsToShoppingCartCommand = {
            uid: uuid(),
            commandType: COMMANDS.AddItemsToShoppingCartCommand,
            items: [item],
        };
        try {
            const response = await OAUTH.authRequest(url, 'POST', { body });
            if (response?.result?.shoppingCart) {
                return { data: response.result.shoppingCart };
            }
            if (response.code) {
                // window.alert(i18n.t(`errors.${response.code}`))
                // throw new Error(response.code);
            }
        } catch (e: any) {
            console.error(e.error);
            window.alert(i18n.t(`errors.${e.response.code}`));
        }
    }
);
const editCart = async (items: IShoppingCartItem[]) => {
    const body: IEditItemsInShoppingCartCommand = {
        uid: uuid(),
        commandType: COMMANDS.EditItemsInShoppingCartCommand,
        items,
    };
    const response = await OAUTH.authRequest(url, 'POST', { body });
    return response;
};
//todo rename to update and allow custom input
export const updateItemAmount = createAsyncThunk(
    'cart/updateItemAmount',
    async (payload: { item: IShoppingCartItem; amount: number }) => {
        const updated = { ...payload.item };
        updated.quantity = payload.amount;
        const response = await editCart([updated]);
        if (response.result.shoppingCart) {
            return { data: response.result.shoppingCart };
        }
    }
);
export const removeItemFromCart = createAsyncThunk(
    'cart/removeItemFromCart',
    async (payload: { item: IShoppingCartItem }) => {
        const updated = { ...payload.item };
        updated.quantity = 0;
        const response = await editCart([updated]);
        if (response.result.shoppingCart) {
            return { data: response.result.shoppingCart };
        }
    }
);
export const getAddresses = createAsyncThunk('cart/getAddresses', async () => {
    const body: IFindMyStoredAddressesQuery = {
        uid: uuid(),
        queryType: QUERIES.FindMyStoredAddressesQuery,
    };
    const response = await OAUTH.authRequest(url, 'POST', { body });
    if (response.result) {
        return { data: response.result };
    }
});
export const purchaseCart = createAsyncThunk(
    'cart/purchaseCart',
    async (payload: { licenses: boolean }) => {
        //todo set subscriptions bool on IPurchaseShoppingCartCommand when true only subscriptions when unset/false only purchases
        const body: IPurchaseShoppingCartCommand = {
            uid: uuid(),
            commandType: COMMANDS.PurchaseShoppingCartCommand,
            PAYMENT_TYPE: payload.licenses ? PAYMENT_TYPE.SUBSCRIPTION : PAYMENT_TYPE.ONE_TIME,
        };
        const response = await OAUTH.authRequest(url, 'POST', {
            body,
        });
        if (response?.result?.headers?.Location) {
            window.location.replace(response.result.headers.Location);
        } else {
            console.error('no redirect url');
        }
    }
);
export const clearCart = createAsyncThunk('cart/clearCart', async () => {
    const body: IRemoveItemsFromShoppingCartCommand = {
        uid: uuid(),
        commandType: COMMANDS.RemoveItemsFromShoppingCartCommand,
    };
    const response = await OAUTH.authRequest(url, 'POST', {
        body,
    });
});
export const addShippingAddress = createAsyncThunk(
    'cart/addShippingAddress',
    async (payload: ISetShipping, { getState }) => {
        const state: IRootState = getState() as IRootState;

        const body: IUpsertShipToAddressCommand = {
            commandType: COMMANDS.UpsertShipToAddressCommand,
            uid: uuid(),
            address: { ...payload, userId: state.user.user.id, email: state.user.user.email },
        };
        const response = await OAUTH.authRequest(url, 'POST', { body });
        if (response.result.shoppingCart) {
            return { data: response.result.shoppingCart };
        }
    }
);
