import { AppDispatch, IRootState } from '../../../../redux';
import { myItemsActions } from '../../../../redux/slices/myItemsSlice';
import { listScene, loadScenes } from '../../../../redux/thunks/myItemsThunks';
import OverviewForm from '../../Overview/Form/OverviewForm';
import MyItemsOverview from '../../Overview/MyItemsOverview';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IScene } from 'maple-common/dist';
import { IPublication } from 'redwood-model/dist';

import styles from './MyBundles.module.scss';

const MyBundles = () => {
    const { showListed, selected } = useSelector((state: IRootState) => state.myItems);
    const { bundles } = useSelector((state: IRootState) => state.myItems.store);
    const [showNew, setShowNew] = useState(false);

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(loadScenes({ listed: showListed }));
    }, []);

    const selectHandler = (item: IScene | IPublication | any, type = 'bundle') => {
        if (type === 'bundle' && !item) {
            setShowNew(true);
            return;
        }
        dispatch(myItemsActions.setSelected(item));
    };

    return (
        <section className="overviewCommon">
            <MyItemsOverview list={bundles} type="bundle" selectItem={selectHandler} />
            {(selected || showNew) && <OverviewForm item={selected} type="bundle" />}
        </section>
    );
};

export default MyBundles;
