import { AppDispatch, IRootState } from '../../../redux';
import { filterActions } from '../../../redux/slices/filterSlice';

import React, { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { debounce, filter } from 'lodash';

import styles from './Search.module.scss';

const Search = (props: { className?: string, type: 'top' | 'side' }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { searchTerm } = useSelector((state: IRootState) => state.filter);

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        debouncedDispatch(event.target?.value);
    };
    let classNames = styles.search
    if (props.className) {
        classNames += (' ' + props.className);
    }


    const debouncedDispatch = useCallback(
        debounce((searchValue) => dispatch(filterActions.changeSearchTerm(searchValue)), 250),
        []
    );

    return (
        <div className={classNames}>
            <input
                onChange={(event) => changeHandler(event)}
                type="text"
                placeholder={t('search')}
                defaultValue={searchTerm}
            />
            <i className="icon-magnifier" />
        </div>
    );
};

export default Search;
