import { configureStore } from '@reduxjs/toolkit';

import filtersListenerMiddleware from './middleware/filtersListenerMiddleware';
import cartReducer from './slices/cartSlice';
import dataReducer from './slices/dataSlice';
import filterReducer from './slices/filterSlice';
import myItemsReducer from './slices/myItemsSlice';
import purchaseReducer from './slices/purchaseSlice';
import sortReducer from './slices/sortSlice';
import storeContentReducer from './slices/storeContentSlice';
import uiReducer from './slices/uiSlice';
import userReducer from './slices/userSlice';

const store = configureStore({
    reducer: {
        user: userReducer,
        ui: uiReducer,
        sort: sortReducer,
        filter: filterReducer,
        myItems: myItemsReducer,
        storeContent: storeContentReducer,
        data: dataReducer,
        cart: cartReducer,
        purchase: purchaseReducer,
    },
    middleware: (getDefaultMiddleware) => [
        filtersListenerMiddleware.middleware,
        ...getDefaultMiddleware(),
    ],
});
export type AppDispatch = typeof store.dispatch;

export type IRootState = ReturnType<typeof store.getState>;

export default store;
