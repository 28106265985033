import { AppDispatch, IRootState } from '../../../../redux';
import { listScene, loadScenes } from '../../../../redux/thunks/myItemsThunks';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IScene } from 'maple-common/dist';

import styles from './MyExercises.module.scss';

const MyExercises = () => {
    return <div className={styles.myExercises}>Exercises</div>;
};

export default MyExercises;
