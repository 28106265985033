import { OAUTH } from '../../OAUTH';
import * as params from '../../configuration/params.json';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { QUERIES } from 'marketplace-common/dist';
import { v4 as uuid } from 'uuid';

const marketplaceUrl = `${params.MARKETPLACE_SERVER}${
    params.MARKETPLACE_SERVER.slice(-1) === '/' ? '' : '/'
}`;

export const getCourses = createAsyncThunk('data/getCourses', async () => {
    //todo get from redwood
    // const body = {
    //     uid: uuid(),
    //     queryType: QUERIES.FindCoursesQuery,
    // };
    // const query = await OAUTH.authRequest(marketplaceUrl, 'POST', { body });
    // return { data: { courses: query.result } };
});
