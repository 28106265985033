import { AppDispatch, IRootState } from '../../../../redux';
import { myItemsActions } from '../../../../redux/slices/myItemsSlice';
import { listScene, loadScenes } from '../../../../redux/thunks/myItemsThunks';
import { useEffectOnce } from '../../../../utilities/customHooks/useEffectOnce';
import OverviewForm from '../../Overview/Form/OverviewForm';
import MyItemsOverview from '../../Overview/MyItemsOverview';

import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IScene } from 'maple-common/dist';
import { IProduct } from 'marketplace-common/dist';
import { IPublication } from 'redwood-model/dist';

const MyMaterials = () => {
    const [firstLoad, setFirstLoad] = useState(true);
    const myItems = useSelector((state: IRootState) => state.myItems);
    const { showListed, selected } = myItems;
    const { scenes: sourceScenes } = myItems.source;
    const { scenes: storeScenes } = myItems.store;
    const scenes: IScene[] | IProduct[] = showListed ? storeScenes : sourceScenes;

    const dispatch = useDispatch<AppDispatch>();

    const selectHandler = (
        item: IScene | IPublication | IProduct | undefined,
        type = 'material'
    ) => {
        dispatch(myItemsActions.setSelected(item));
    };

    return (
        <section className="overviewCommon">
            <MyItemsOverview list={scenes} type="material" selectItem={selectHandler} />
            {selected && <OverviewForm item={selected} type="material" />}
        </section>
    );
};

export default MyMaterials;
