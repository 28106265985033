import React from 'react';

import styles from './ErrorPages.module.scss';

const Error404 = () => {
    return (
        <div className={styles.error}>
            <p>404 not found</p>
        </div>
    );
};

export default Error404;
