import React from 'react';

import styles from './Files.module.scss';

const Files = () => {
    return (
        <div className={styles.files}>
            <p>Files</p>
        </div>
    );
};

export default Files;
