import { IDataState } from '../interfaces/IDataState';
import { getCourses } from '../thunks/dataThunks';
import { createSlice } from '@reduxjs/toolkit';

import { BOOK_EDITIONS, MERCHANT_TYPE } from 'marketplace-common/dist';
import { v4 as uuid } from 'uuid';

const initialState: IDataState = {
    courses: [
        { uid: uuid(), code: 'aard', name: 'aardrijkskunde' },
        { uid: uuid(), code: 'nat', name: 'natuurwetenschappen' },
        { uid: uuid(), code: 'ges', name: 'geschiedenis' },
    ],
    years: [1, 2, 3, 4, 5, 6, 7],
    suppliers: Object.values(MERCHANT_TYPE),
    editions: Object.values(BOOK_EDITIONS),
};

const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCourses.fulfilled, (state, action) => {
            //todo get from redwood
            // const { courses } = action.payload.data;
            // state.courses = courses;
        });
    },
});
export const dataActions = dataSlice.actions;

export default dataSlice.reducer;
