import { OAuth } from 'educateme-oauth';
import { PUBLIC_USER } from 'marketplace-common/dist';

import * as params from './configuration/params.json';

let redwoodOauthApiUrl = params['REDWOOD'];
if (!redwoodOauthApiUrl.endsWith('/')) {
    redwoodOauthApiUrl += '/';
}
redwoodOauthApiUrl += 'oauth/v2';
export const OAUTH = new OAuth(
    params['OATH_CLIENT_ID'],
    params['OATH_CLIENT_SECRET'],
    location.origin,
    redwoodOauthApiUrl,
    params['REDWOOD'],
    true,
    PUBLIC_USER
);
