import { AppDispatch, IRootState } from '../../../redux';
import { sortActions } from '../../../redux/slices/sortSlice';

import React, { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Sort.module.scss';
import { ISortItem } from '../../../redux/interfaces/sort/ISortItem';

const Sort = (props: { className?: string, type: 'top' | 'side' }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const typeRef = useRef<HTMLSelectElement>(null)
    const sortMethods = useSelector((state: IRootState) => state.sort);
    const selected: string = Object.keys(sortMethods).find(key => sortMethods[key].value) || 'abc';
    const direction = sortMethods[selected].descending ? '0' : '1'
    const setSortMethod = (key: string) => {
        dispatch(sortActions.selectSortParameter({ method: key }));
    };
    const sortChanged = (event: ChangeEvent) => {
        const method: string = (event?.target as HTMLSelectElement)?.value;
        const sortType = sortMethods[method].descending;
        if  (typeRef.current) {
            typeRef.current.value = sortType ? '1' : '0'
        }
        dispatch(sortActions.selectSortParameter({ method, type: sortType }))
    }
    const directionChanged = (event: ChangeEvent) => {
        const value = (event.target as HTMLSelectElement)?.value
        if (value) {
            const isDescending = !!parseInt(value);
            //todo pass method and type
            const method = Object.keys(sortMethods).find(key => sortMethods[key].value);
            console.log({isDescending, method});
            dispatch(sortActions.selectSortParameter({ method, type: isDescending }));
        }
    }
    let classNames = styles.sort
    if (props.className) {
        classNames += (' ' + props.className);
    }

    const sortOptions = Object.keys(sortMethods).map((key) => {
        return (
            <li
                key={key}
                className={sortMethods[key].value ? styles.active : ''}
                onClick={() => setSortMethod(key)}
            >
                {t(`sortOptions.${key}`)}
                {sortMethods[key].value && (sortMethods[key].descending ? ' ▲' : ' ▼')}
            </li>
        );
    });
    const selectOptions = Object.keys(sortMethods).map(key => {
        return <option key={'select'+key} value={key}>{key}</option>
    })


    return (
        <>
            <div className={styles.sortDropdown}>
                <span>{t('sort')}:</span>
                <select value={selected} name='sort' id='sort' onChange={(event) => {sortChanged(event)}} >
                    {selectOptions}
                </select>
                <select value={direction} name='type' id='type' ref={typeRef} onChange={(event) => {directionChanged(event)}}>
                    <option value='0'>oplopend</option>
                    <option value='1'>aflopend</option>
                </select>
            </div>
            <div className={classNames}>
                <span>{t('sort')}:</span>
                <ul>{sortOptions}</ul>
            </div>
        </>

    );
};

export default Sort;
