import { OAUTH } from '../../OAUTH';
import * as params from '../../configuration/params.json';
import { AppDispatch } from '../../redux';
import { uiActions } from '../../redux/slices/uiSlice';

import React from 'react';
import { useDispatch } from 'react-redux';

import { COMMANDS_MARKETPLACE, IGetMySceneCommand } from 'maple-common/dist';

import styles from './Exercises.module.scss';

const Exercises = () => {
    const dispatch = useDispatch<AppDispatch>();
    const handleShow = () => {
        dispatch(uiActions.showCollectionControls());
    };
    const handleHide = () => {
        dispatch(uiActions.hideCollectionControls());
    };
    const handleLogin = () => {
        const url = OAUTH.manualLogin();

        window.location.href = url;
    };

    const handleGetoAuthInfo = async () => {
        const test = await OAUTH.getOAuthInfo();
    };

    const marketplacepost = async () => {
        const body: IGetMySceneCommand = {
            type: COMMANDS_MARKETPLACE.GetMyScenes,
            payload: {
                limit: 15,
                skip: 0,
            },
        };
        try {
            const result = await OAUTH.authRequest(
                `${params.MAPLE_SERVER}api/maple-client`,
                'POST',
                { body }
            );
        } catch (e) {
            console.error(e);
        }
    };
    return (
        <div className={styles.exercises}>
            <div>
                <button onClick={handleShow}>show</button>
                <button onClick={handleHide}>hide</button>
                <button onClick={handleLogin}>login</button>
                <button onClick={handleGetoAuthInfo}>get info</button>
                <button onClick={marketplacepost}>post</button>
            </div>
        </div>
    );
};

export default Exercises;
