import React from 'react';

const MerchantCreate = () => {
    return (
        <div>
            <p>Merchant create</p>
        </div>
    );
};

export default MerchantCreate;
