import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const PaymentSuccess = () => {
    const { t, i18n } = useTranslation();
    return (
        <div>
            <p>Betaling geslaagd</p>
            <NavLink to={'/' + t('routes.my-purchases')}>Mijn aankopen</NavLink>
        </div>
    );
};

export default PaymentSuccess;
