import { IRootState } from '../../../redux';
import { getThumbnail, isProduct, isRedwoodPub, isScene } from '../../../utilities/utilities';

import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { IScene } from 'maple-common/dist';
import { IProduct, PRODUCT_TYPE } from 'marketplace-common/dist';
import { IPublication } from 'redwood-model/dist';

import MyItemsOverviewItem from './Item/MyItemsOverviewItem';
import styles from './MyItemsOverview.module.scss';
import './overviewCommon.scss';

export interface IOverview {
    list: IScene[] | IPublication[] | IProduct[] | any[];
    type: 'book' | 'file' | 'material' | 'exercise' | 'bundle';
    selectItem: (item: IScene | IPublication | IProduct | undefined, type?: string) => void;
}

const MyItemsOverview = (props: IOverview) => {
    const { list, type, selectItem } = props;
    const { selected } = useSelector((state: IRootState) => state.myItems);
    const checkSelected = (element: IScene | IPublication | IProduct): boolean => {
        if (!selected) {
            return false;
        }
        const product: IProduct | undefined = isProduct(element);
        const selectedProduct: IProduct | undefined = isProduct(selected);
        if (product) {
            if (!selectedProduct) return false;

            const lookupTable = {
                [PRODUCT_TYPE.MAPLE]: 'scene_uid',
                [PRODUCT_TYPE.REDWOOD]: 'publication_id',
                [PRODUCT_TYPE.PRUNUS]: 'prunus_uri',
            };
            const idType = lookupTable[product.productRef.productTypes[0]];

            return product.productRef[idType] === selectedProduct.productRef[idType];
        }
        const scene: IScene | undefined = isScene(element);
        const selectedScene: IScene | undefined = isScene(selected);
        if (scene) {
            if (!selectedScene) return false;
            return scene.id === selectedScene.id;
        }
        const redwood: IPublication | undefined = isRedwoodPub(element);
        const selectedRedwood: IPublication | undefined = isRedwoodPub(selected);
        if (redwood) {
            // console.log({redwood});
            if (!selectedRedwood) return false;
            return redwood.id === selectedRedwood.id;
        }

        return false;
    };

    let items: JSX.Element | JSX.Element[] = <p>{type} loading....</p>;
    if (list.length > 0) {
        items = list.map((element: IScene | IPublication | any) => {
            return (
                <Fragment key={(element.id || element.uid) + props.type}>
                    <MyItemsOverviewItem
                        element={element}
                        type={type}
                        selected={checkSelected(element)}
                        thumbnail={getThumbnail(element)}
                        onSelect={() => selectItem(element, type)}
                    />
                </Fragment>
            );
        });
    }

    return (
        <div className={styles.overview}>
            {/*{props.type === 'bundle' && <p onClick={() => selectItem(undefined, 'bundle')}>new</p>}*/}
            {items}
        </div>
    );
};

export default MyItemsOverview;
