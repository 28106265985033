import React from 'react';

import styles from './InputInlineGroup.module.scss';

const InputInlineGroup = (props: { children?: React.ReactNode; className?: string }) => {
    return (
        <div className={`${styles.inputInlineGroup} ${props.className}`}>
            <div className={styles.container}>{props.children}</div>
        </div>
    );
};

export default InputInlineGroup;
