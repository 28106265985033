import Currency from '../../../Elements/Currency/Currency';

import React, { Fragment, useState } from 'react';

import { IPurchase, PRODUCT_TYPE, PURCHASE_TYPE } from 'marketplace-common/dist';

import styles from './PurchaseItem.module.scss';
import PurchasedItem from './PurchasedItem/PurchasedItem';

const PurchaseItem = (props: { purchase: IPurchase }) => {
    const { purchase } = props;
    const date = new Date(purchase.created_at).toLocaleDateString('be');
    const [collapsed, setCollapsed] = useState(true);
    const collapseClicked = () => {
        setCollapsed((prev) => !prev);
    };
    //temp until total cost is inside IPurchase (including shipping)
    const totalPrice = purchase.items.reduce((prev, item) => {
        const isPaper =
            item.productRef.productTypes.includes(PRODUCT_TYPE.REDWOOD || PRODUCT_TYPE.HEMLOCK) &&
            item.purchaseType === PURCHASE_TYPE.SALE;
        const price =
            ((isPaper
                ? item.price.paperPrice
                : item.purchaseType === PURCHASE_TYPE.SALE
                ? item.price.salePrice
                : item.price.leasePrice) || 0) * item.quantity;
        return prev + price;
    }, 0);
    const items: JSX.Element[] = purchase.items.map((item, index) => {
        return (
            <Fragment key={item.productRef.productName + index}>
                <PurchasedItem item={item} />
            </Fragment>
        );
    });
    return (
        <div className={styles.purchaseItem}>
            <div className={styles.main}>
                <h3>{date}</h3>
                <span>
                    <Currency>{totalPrice}</Currency>
                </span>
                <span onClick={collapseClicked}>{collapsed ? '+' : '-'}</span>
            </div>
            <div className={`${styles.items} ${collapsed ? styles.collapsed : styles.open}`}>
                {items}
            </div>
        </div>
    );
};

export default PurchaseItem;
