import InputInlineGroup from '../../../../Elements/InputInlineGroup';
import { AppDispatch, IRootState } from '../../../../redux';
import { listBundle, listPublication, listScene } from '../../../../redux/thunks/myItemsThunks';
import {
    isProduct,
    isRedwoodPub,
    isScene,
    preventEnter as preventEnterCommon,
} from '../../../../utilities/utilities';

import React, { useEffect } from 'react';
import { Controller, useForm, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select, { GroupBase, OptionsOrGroups } from 'react-select';

import { IPublicationData, IScene } from 'maple-common/dist';
import { IProduct } from 'marketplace-common/dist';
import { IPublication } from 'redwood-model/dist';

import styles from './OverviewForm.module.scss';
import './multiSelect.scss';

const OverviewForm = (props: {
    item: IScene | IPublication;
    type: 'book' | 'file' | 'material' | 'exercise' | 'bundle';
}) => {
    //hooks
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const {
        register,
        handleSubmit,
        control,
        setValue,
        trigger,
        reset,
        formState: { errors },
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        criteriaMode: 'all',
    });

    const publications = useSelector((state: IRootState) => state.user.user.publications);
    const { courses, years } = useSelector((state: IRootState) => state.data);

    const product: IProduct | undefined = isProduct(props.item);
    const scene: IScene | undefined = isScene(props.item);
    const redwood: IPublication | undefined = isRedwoodPub(props.item);

    const isPublication = props.type === 'book';

    const salePriceVal = useWatch({ control, name: 'salePrice' });
    const paperPriceVal = useWatch({ control, name: 'paperPrice' });
    const leasePriceVal = useWatch({ control, name: 'leasePrice' });
    let selectedPubs: OptionsOrGroups<any, GroupBase<any>> = [];
    let pubOptions: OptionsOrGroups<any, GroupBase<any>> | undefined;
    let courseOptions: { value: string | null; label: string }[] = [
        { value: null, label: t('create.make-selection') },
    ];
    if (courses?.length) {
        courseOptions = [
            ...courseOptions,
            ...Object.values(courses).map((course) => {
                return { value: course.code, label: t(`courses.${course.code}`) };
            }),
        ];
    }
    const yearOptions = [
        { value: null, label: t('create.make-selection') },
        ...years.map((year) => {
            return { value: year, label: `${year}e ${t('create.year')}` };
        }),
    ];
    if (publications?.length) {
        pubOptions = publications.map((pub: IPublication) => {
            return { value: pub.id, label: pub.slug };
        });
        if (scene) {
            selectedPubs = scene?.publications
                ?.filter((pub: IPublicationData) => pub.publicationId)
                .map((pub: IPublicationData) => {
                    return {
                        value: pub.publicationId,
                        label: publications.find((p: IPublication) => p.id === pub.publicationId)
                            .slug,
                    };
                });
        }
        if (product) {
            selectedPubs = product.licenses
                ?.filter((license) => license.id)
                .map((license) => {
                    return {
                        value: license.id,
                        label: publications.find((p: IPublication) => p.id === license.id).slug,
                    };
                });
        }
    }
    const { touchedFields, isSubmitting } = useFormState({
        control,
    });

    const { name = '' } = scene || redwood || { name: product?.productRef.productName };
    const { description = '' } = scene || { description: undefined };

    //populate fields on fresh load / scene change
    useEffect(() => {
        if (product) {
            reset(undefined, { keepDirty: false });
            setValue('name', name);
            setValue('course', product.courseCode);
            setValue('year', product.year);
            setValue('tags', product.tags);
            setValue('salePrice', product.price.salePrice);
            setValue('leasePrice', product.price.leasePrice);
            setValue('paperPrice', product.price.paperPrice);
            setValue('publications', selectedPubs);
            setValue('description', product.description);
        } else {
            reset(undefined, { keepDirty: false });
            setValue('name', name);
            setValue('course', isPublication ? redwood?.properties.key_prefix.toString() : null);
            setValue('year', isPublication ? redwood?.properties.year.toString() : null);
            setValue('tags', null);
            setValue('salePrice', undefined);
            setValue('leasePrice', undefined);
            setValue('paperPrice', undefined);
            setValue('publications', selectedPubs);
            setValue('description', description);
        }
    }, [props.item]);

    const displayError = (error: any, field: string): boolean => {
        return !!error && touchedFields[field];
    };
    const onSubmit = (data: any) => {
        if (props.type === 'material' && scene) {
            dispatch(listScene({ scene, data }));
        }
        if (props.type === 'book' && redwood) {
            dispatch(listPublication({ publication: redwood, data }));
        }
        if (props.type === 'bundle') {
            dispatch(listBundle({ data }));
        }
    };
    const preventEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        trigger();
        preventEnterCommon(event);
    };
    const isRequired = (type: string) => {
        const check: { [key: string]: boolean } = {
            sale: isPublication ? !(leasePriceVal || paperPriceVal) : !leasePriceVal,
            lease: isPublication ? !(salePriceVal || paperPriceVal) : !salePriceVal,
            physical: !(leasePriceVal || salePriceVal),
        };
        return check[type];
    };
    return (
        <div className={styles.formContainer}>
            <h2>{t(`create.add-${props.type}`)}</h2>
            <form onSubmit={handleSubmit((e) => onSubmit(e))}>
                {/*Name*/}
                <label htmlFor="name">{t('create.labels.name')}</label>
                <input
                    className={`${errors.name ? styles.error : ''}`}
                    onKeyDown={(e) => preventEnter(e)}
                    id="name"
                    {...register('name', {
                        required: { value: true, message: t('create.errors.name.required') },
                    })}
                />
                {errors.name && <p className={styles.errorMessage}>{errors.name.message} </p>}
                {/*Course*/}
                <label htmlFor="course">{t('create.labels.course')}</label>
                <Controller
                    control={control}
                    name="course"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                            className={`react-select ${styles.multiSelect}`}
                            classNamePrefix="react-select"
                            options={courseOptions}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            placeholder={t('create.make-selection')}
                        />
                    )}
                />
                {/*Year*/}
                <label htmlFor="year">{t('create.labels.year')}</label>
                <Controller
                    control={control}
                    name="year"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                            className={`react-select ${styles.multiSelect}`}
                            classNamePrefix="react-select"
                            options={yearOptions}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            placeholder={t('create.make-selection')}
                        />
                    )}
                />
                {/*Publications*/}
                {!isPublication && (
                    <>
                        <label htmlFor="publications">{t('create.labels.publications')}</label>
                        <Controller
                            control={control}
                            name="publications"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Select
                                    className={`react-select ${styles.multiSelect}`}
                                    classNamePrefix="react-select"
                                    isMulti={true}
                                    options={pubOptions}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    placeholder={t('create.make-selection')}
                                />
                            )}
                        />
                    </>
                )}
                {/*Description*/}
                <label htmlFor="description">{t('create.labels.description')}</label>
                <textarea id="description" {...register('description')} />
                {/*Tags todo: better input*/}
                <label htmlFor="tags">{t('create.labels.tags')}</label>
                <input
                    onKeyDown={(e) => preventEnter(e)}
                    id="tags"
                    placeholder={t('create.labels.tags')}
                    {...register('tags')}
                />{' '}
                <br />
                <InputInlineGroup className={styles.inputGroup}>
                    <label htmlFor="salePrice">{t('create.labels.sale-price')}</label>
                    <input
                        className={errors.salePrice ? styles.error : ''}
                        lang="nl"
                        step="0.05"
                        onKeyDown={(e) => preventEnter(e)}
                        type="number"
                        id="salePrice"
                        placeholder="0.00"
                        {...register('salePrice', {
                            required: {
                                value: isRequired('sale'),
                                message: t(
                                    `create.errors.price.required${isPublication ? '.pub' : '.reg'}`
                                ),
                            },
                            min: { value: 0, message: t('create.errors.price.min') },
                        })}
                    />
                    <label htmlFor="leasePrice">{t('create.labels.lease-price')}</label>
                    <input
                        className={errors.leasePrice ? styles.error : ''}
                        lang="nl"
                        step="0.05"
                        onKeyDown={(e) => preventEnter(e)}
                        type="number"
                        id="leasePrice"
                        placeholder="0.00"
                        {...register('leasePrice', {
                            required: {
                                value: isRequired('lease'),
                                message: t(
                                    `create.errors.price.required${isPublication ? '.pub' : '.reg'}`
                                ),
                            },
                            min: { value: 0, message: t('create.errors.price.min') },
                        })}
                    />
                </InputInlineGroup>
                {isPublication && (
                    <>
                        <InputInlineGroup className={styles.inputGroup}>
                            <label htmlFor="paperPrice">{t('create.labels.physical')}</label>
                            <input
                                className={errors.paperPrice ? styles.error : ''}
                                lang="nl"
                                step="0.05"
                                onKeyDown={(e) => preventEnter(e)}
                                type="number"
                                id="paperPrice"
                                placeholder="0.00"
                                {...register('paperPrice', {
                                    required: {
                                        value: isRequired('physical'),
                                        message: t(
                                            `create.errors.price.required${
                                                isPublication ? '.pub' : '.reg'
                                            }`
                                        ),
                                    },
                                    min: { value: 0, message: t('create.errors.price.min') },
                                })}
                            />
                            {paperPriceVal && (
                                <>
                                    <label htmlFor="weight">{t('create.labels.weight')}</label>
                                    <input
                                        className={errors.weight ? styles.error : ''}
                                        lang="nl"
                                        step="0.05"
                                        onKeyDown={(e) => preventEnter(e)}
                                        type="number"
                                        id="weight"
                                        placeholder="0.00"
                                        {...register('weight', {
                                            required: {
                                                value: !!paperPriceVal,
                                                message: t('create.errors.weight.required'),
                                            },
                                            min: {
                                                value: 0,
                                                message: t('create.errors.price.min'),
                                            },
                                        })}
                                    />
                                </>
                            )}
                            {!paperPriceVal && <div style={{ width: 'calc(46% - 20px)' }} />}
                        </InputInlineGroup>
                        {}
                    </>
                )}
                {(displayError(errors.leasePrice, 'leasePrice') ||
                    displayError(errors.salePrice, 'salePrice') ||
                    errors.weight) && (
                    <p className={styles.errorMessage}>
                        {' '}
                        {(errors.leasePrice || errors.salePrice || errors.weight).message}{' '}
                    </p>
                )}
                <input type="submit" value={t('create.place-in-marketplace')} />
            </form>
        </div>
    );
};

export default OverviewForm;
