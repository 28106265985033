import { AppDispatch, IRootState } from '../../redux';
import { addItemToCart } from '../../redux/thunks/cartThunks';

import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BOOK_EDITIONS, IProduct, PURCHASE_TYPE } from 'marketplace-common/dist';
import { IPublication } from 'redwood-model/dist';

import BookItem from './BookItem/BookItem';
import styles from './Books.module.scss';

const Books = () => {
    const { publications } = useSelector((state: IRootState) => state.storeContent);
    const dispatch = useDispatch<AppDispatch>();
    const addToCart = (type: string, product: IProduct) => {
        dispatch(addItemToCart({ type, item: product, quantity: 1 }));
    };
    const items: JSX.Element[] = publications.map((pub: IProduct) => (
        <Fragment key={pub.uid}>
            <BookItem product={pub} />
        </Fragment>

        // <Fragment key={pub.uid}>
        //     {/*<BookItemTemp product={pub} />*/}
        //     <div>
        //         <h3>{pub.productRef.productName}</h3>
        //         <p>
        //             {pub.year} {pub.courseCode}
        //         </p>
        //         {/*{pub.price.salePrice && <button onClick={() => addToCart(PURCHASE_TYPE.SALE, pub)}>buy {pub.price.salePrice}</button>}*/}
        //         {pub.price.leasePrice && (
        //             <button onClick={() => addToCart(PURCHASE_TYPE.LEASE, pub)}>
        //                 lease {pub.price.leasePrice}
        //             </button>
        //         )}
        //         {pub.price.paperPrice && (
        //             <button onClick={() => addToCart(BOOK_EDITIONS.PAPER, pub)}>
        //                 paper {pub.price.paperPrice}
        //             </button>
        //         )}
        //     </div>
        // </Fragment>
    ));

    return <div className={styles.books}>{items}</div>;
};

export default Books;
